import React, { Component } from "react";
import ReactDOM from "react-dom";
import { fadeIn, fadeOut } from "react-animations";
import Radium, { StyleRoot } from "radium";

// Root div to render component
var popupMessageRoot = document.getElementById("popupMessage");

// Timeout variables
var start, stop;

/**
 * This function can be used to easily instantiate a popup message
 * that directly renders to DOM under the popupMessageRoot div in index.html
 *
 * Default position is: xAxis: 230px, yAxis: 53px
 *
 * Returnes a PopupMessage
 * Example: import { PopMsg } from "../common/PopupMessage";
 * @param {message, top, bottom, left, right, color, duration} options
 */
export function PopMsg(options) {
  ReactDOM.render(
    <PopupMessage options={options} show={true} />,
    document.getElementById("popupMessage")
  );
}

/**
 * Small popup message helper class for unintrusive messages
 * Author: Øyvind Johannessen
 * Version: 0.1
 */
class PopupMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      styles: {
        position: "fixed",
        border: "1px solid #1a1a1a",
        backgroundColor: this.props.options.color
          ? this.props.options.color
          : "orange",
        paddingLeft: "10px",
        paddingRight: "10px",
        zIndex: "999",
        top: this.props.options.top ? this.props.options.top : "53px",
        bottom: this.props.options.bottom ? this.props.options.bottom : "None",
        left: this.props.options.left ? this.props.left : "230px",
        right: this.props.options.right ? this.props.options.right : "None",
        animation: this.props.options.duration
          ? "x " + this.props.options.duration + "s"
          : "x 1s",
        animationName: Radium.keyframes(fadeIn, "fadeIn")
      },
      isVisible: this.props.show
    };
  }

  /**
   * How long the message is being viewd.
   * 7 seconds
   */
  timeOut() {
    // Fade out after 5 seconds
    var durationStart = this.props.options.duration;
    var durationEnd = this.props.options.duration;
    durationEnd += durationEnd;
    durationEnd *= 1000;
    start = setTimeout(
      () => {
        this.setState({
          styles: {
            position: "fixed",
            border: "1px solid #1a1a1a",
            backgroundColor: this.props.options.color
              ? this.props.options.color
              : "orange",
            paddingLeft: "10px",
            paddingRight: "10px",
            zIndex: "999",
            top: this.props.options.top ? this.props.options.top : "53px",
            bottom: this.props.options.bottom
              ? this.props.options.bottom
              : "None",
            left: this.props.options.left ? this.props.left : "230px",
            right: this.props.options.right ? this.props.options.right : "None",
            animation: durationStart ? "x " + durationStart + "s" : "x 1s",
            animationName: Radium.keyframes(fadeOut, "fadeOut")
          }
        });
      },
      durationStart ? durationStart * 1000 : 1000
    );

    // Remove component after 7 seconds
    stop = setTimeout(
      () => {
        this.setState({ isVisible: false });
        ReactDOM.render(null, document.getElementById("popupMessage"));
      },
      durationEnd ? Math.round(durationEnd) : 2000
    );
  }

  componentWillUnmount() {
    clearTimeout(start);
    clearTimeout(stop);
  }

  render() {
    if (this.state.isVisible) {
      return ReactDOM.createPortal(
        <StyleRoot>
          <div ref="popmsg" style={this.state.styles}>
            <span>
              {this.props.options.message
                ? this.props.options.message
                : "No message"}
            </span>
          </div>
        </StyleRoot>,
        popupMessageRoot,
        this.timeOut()
      );
    } else {
      return null;
    }
  }
}

export default PopupMessage;
