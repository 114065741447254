import React, { Component } from "react";
import ApproveUserItem from "./ApproveUserItem";
import ColumnLayout from "./ColumnLayout";

/**
 * Class for rendereing a list for ApproveUserItem components
 * Author: Øyvind Johannessen
 * Version: 0.1
 */
class AppendingUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: this.props.appendingUsersList
    };
  }
  render() {
    return (
      <ColumnLayout stretchWidth={true}>
        {/* Receives props from Dashboard here, Dashboard receives props from redux state (appendingUsers.users) */}
        {this.props.appendingUsersList.map(user => (
          <ApproveUserItem
            key={user._id}
            userId={user._id}
            name={user.name}
            phone={user.phone}
            employeeNumber={user.employeeNumber}
          />
        ))}
      </ColumnLayout>
    );
  }
}

export default AppendingUsers;
