import React from "react";
export class AddUserButton extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (<div className="pointer iconButtonActive" style={{
            display: "block",
            height: "40px",
            width: "40px",
            marginLeft: this.props.pushRight ? "auto" : "",
            marginRight: this.props.pushLeft ? "auto" : "",
            marginTop: this.props.pushBottom ? "auto" : "",
            marginBottom: this.props.pushTop ? "auto" : "",
            padding: this.props.padding
        }}>
            <img alt="person" src={require("../../img/ic_person_add_48px.png")} onClick={this.props._selectUser} />
        </div>);
    }
}
