import React, { Component } from "react";
import { connect } from "react-redux";
import { removeCartUser } from "../../actions/shoppingcartActions";
import ShoppingCart from "./ShoppingCart";
import { ItemLabel } from "../common/ItemLabel";
import RowLayout from "../common/RowLayout";
// CSS
import "./css/navbar-style.css";
class Navbar extends Component {
  render() {
    function toggleClass() {
      var hamburger = document.getElementsByClassName("hamburger");
      hamburger[0].classList.toggle("onChange");
      hamburger[1].classList.toggle("onChange");
      hamburger[2].classList.toggle("onChange");
      var sidebar = document.getElementById("sidebar");
      sidebar.classList.toggle("onChange");
    }
    return (
      <div className="navbar-container">
        <RowLayout spaceRight={true} spaceLeft={true}>
          <nav className="hamburger-nav">
            <div className="hamburger-container" onClick={toggleClass}>
              <span className="hamburger bar1" />
              <span className="hamburger bar2" />
              <span className="hamburger bar3" />
            </div>
          </nav>
          <nav className="navbar fixed-top navbar-light">
            <a href=".">
              <img
                id="navBarLogo"
                src={require("../../img/logo-header-3x.png")}
                alt="forsvaret logo"
              />
            </a>
          </nav>



          <div style={{ marginLeft: 'auto', height: '50px' }} >

            {this.props.cartUser.name !== " " ? <img
              src={require("../../img/ic_delete_48px.png")}
              alt="Fjern valgt person"
              onClick={() => {
                this.props.removeCartUser();
              }}
              className="pointer iconButtonActive"
            /> : ""}
          </div>


          <div style={{ height: '50px', padding: '10px 10px 10px 10px' }} >

            <ItemLabel text={this.props.cartUser.name} color="white" />
          </div>

          <ShoppingCart />
        </RowLayout>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  cartUser: state.shoppingCart.user
});

export default connect(
  mapStateToProps,
  { removeCartUser }
)(Navbar);
