import store from "../store";

export function isSysDevSysAdmOrLender() {
    const state = store.getState();
    const userAccess = state.auth.user.userAccess;
    const { sysAdm, sysDev, generalLender } = userAccess;
    if(sysAdm || sysDev || generalLender) return true;
    return false;
}

export function isBorrower() {
    const state = store.getState();
    const userAccess = state.auth.user.userAccess;
    const { borrower } = userAccess;
    if(borrower) return true;
    return false;
}