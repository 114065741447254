import React from "react";

export class AddProductButton extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="pointer iconButtonActive" style={{
                display: "block",
                height: "40px",
                width: "40px",
                marginLeft: this.props.pushRight ? "auto" : "",
                marginRight: this.props.pushLeft ? "auto" : "",
                marginTop: this.props.pushBottom ? "auto" : "",
                marginBottom: this.props.pushTop ? "auto" : "",
                padding: this.props.padding
            }}>
                <img src={require("../../img/ic_add_shopping_cart_48px.png")} onClick={this.props._addToCart} alt={"Add to shopping cart"} />
            </div>
        );
    }
}
