import React from "react";
import { ItemLabel } from "./ItemLabel";

export const CountStickerLabel = (props) => (
    <div style={{
        position: "absolute",
        top: props.top ? props.top : "-10px",
        left: props.left ? props.left : "-10px",
        right: props.right,
        bottom: props.bottom,
        backgroundColor: props.count <= 0 ? "red" : props.backgroundColor ? props.backgroundColor : "#1A1A1A",
        borderRadius: "50%",
        padding: "6px",
        width: props.width ? props.width : "12px",
        height: props.height ? props.height : "12px",
        textAlign: "center",
        border: props.border ? props.border : "1px solid #DDDDDD"
    }}>
        {props.children ? props.children : <ItemLabel text={props.count} fontSize="0.8em" position="absolute" width="100%" top={props.countTop ? props.countTop : "2px"} left="0" color="white" />}
    </div>);
