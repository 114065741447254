import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import "./css/style.css";

import { Provider } from "react-redux";
import store from "./store";

import "./App.css";

// Components
import Login from "../src/components/Auth/Login";
import Sidemenu from "../src/components/menu/Sidemenu";
import Navbar from "../src/components/navbar/Navbar";
import Dashboard from "./components/dashboard/Dashboard";
import Loan from "./components/loan/Loan";
import RegisterNewProfile from "./components/borrower/RegisterNewProfile";
import ShoppingCart from "./components/navbar/ShoppingCart";
import System from "../src/components/system/System";
import BorrowerContainer from "./components/borrower/BorrowerContainer";
import ProductContainer from "./components/product/ProductContainer";
import DesignContainer from "./components/DesignContainer";

if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
}

// User can idle for 30 seconds, after this user will be logged out
// Token is expired at 60 minutes
var checker;
var idleTime = 0;
function startChecker() {
  checker = setInterval(() => {
    // Check for token
    if (localStorage.jwtToken) {
      const decoded = jwt_decode(localStorage.jwtToken);
      // Check for expired token
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser());
      }
      idleTime++;
      // How long before you are logged out.
      if (idleTime === 500) {
        store.dispatch(logoutUser());
      }
    }
  }, 1000);
}

// Reset iddle timer and clear interval. Repeat the token checker.
window.onmousemove = () => {
  idleTime = 0;
  clearInterval(checker);
  startChecker();
};

// Start the checker on load
//startChecker();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <React.Fragment>
            <Route exact path="/" component={Login} />
            <section className="App">
              <Route exact path="/design" component={DesignContainer} />
              <Route exact path="/dashbord" component={Sidemenu} />
              <Route exact path="/utlan" component={Sidemenu} />
              <Route exact path="/lantaker" component={Sidemenu} />
              <Route exact path="/produkt" component={Sidemenu} />
              <Route exact path="/system" component={Sidemenu} />

              <Route exact path="/dashbord" component={Navbar} />
              <Route exact path="/lantaker" component={Navbar} />
              <Route exact path="/produkt" component={Navbar} />
              <Route exact path="/system" component={Navbar} />
              <Route exact path="/utlan" component={Navbar} />
              {/* New Container for Låntaker route. All components for this route is placed inside BorrowerContainer */}
              <Route exact path="/dashbord" component={Dashboard} />
              <Route exact path="/utlan" component={Loan} />
              <Route exact path="/lantaker" component={BorrowerContainer} />
              <Route exact path="/produkt" component={ProductContainer} />
              <Route exact path="/system" component={System} />

              {/* Awaits new routeName for registration of user that are waiting approval */}
              <Route
                exact
                path="/request-registration"
                render={props => (
                  <div>
                    <a href="/">
                      <button>
                        {" "}
                        <i className="fa fa-arrow-left"></i>Tilbake til
                        hjemmesiden
                      </button>
                    </a>
                    <RegisterNewProfile
                      {...props}
                      userAccessView={false}
                      routeName="api/users/request-registration"
                    />
                  </div>
                )}
              />
            </section>
          </React.Fragment>
        </Router>
      </Provider>
    );
  }
}

export default App;
