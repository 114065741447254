import React, { Component } from "react";

const style = {
  display: "flex",
  position: "absolute",
  left: "240px",
  top: "100px",
  height: "calc(100% - 100px)",
  width: "calc(100% - 240px)"
};

class ContentContainer extends Component {
  render() {
    return (
      <div
        className={this.props.className ? this.props.className : ""}
        style={style}
      >
        {this.props.children}
      </div>
    );
  }
}

export default ContentContainer;
