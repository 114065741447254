import React, { Component } from "react";
import { connect } from "react-redux";

import {
  approveAppendingUser,
  denyAppendingUser
} from "../../actions/appendingUsersAction";
import { PopMsg } from "./PopupMessage";
import ColumnLayout from "./ColumnLayout";
import RowLayout from "./RowLayout";
import { ItemLabel } from "./ItemLabel";

/**
 * Class for representing a user that has not yet been approved by an operator
 * Author: Øyvind Johannessen
 * Version: 0.1
 */
class ApproveUserItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      handled: false // If false, then this user has not yet been approved or denied.
    };
  }

  /**
   * Approves this appending user registration
   */
  approveUser() {
    const userId = { userId: this.props.userId };
    this.props.approveAppendingUser(userId).then(res => {
      var message = "";
      var color = "";
      if (this.props.onHandleResponse.success === false) {
        message = "Noe gikk galt, prøv igjen";
        color = "red";
      } else {
        message = this.props.name + " er godkjent!";
        color = "green";

        this.setState({ handled: true });
      }

      PopMsg({
        message: message,
        right: "0",
        left: "None",
        color: color
      });
    });
  }

  /**
   * Deny this appending user registration
   */
  denyUser() {
    const userId = { userId: this.props.userId };
    this.props.denyAppendingUser(userId).then(res => {
      var message = "";
      var color = "";
      if (this.props.onHandleResponse.success === false) {
        message = "Noe gikk galt, prøv igjen";
        color = "red";
      } else {
        message = this.props.name + " er avslått";
        color = "orange";

        this.setState({ handled: true });
      }

      PopMsg({
        message: message,
        right: "0",
        left: "None",
        color: color
      });
    });


  }

  render() {
    const { handled } = this.state;
    return handled === true ? (
      ""
    ) : (
        <RowLayout className="whiteBackground borderRadiusEight tenPixelPadding dropShadow">
          <ColumnLayout>
            <RowLayout>
              <ItemLabel text="Navn:" bold={true} italic={true} color="#686868" paddingRight="10px" />
              <ItemLabel text={this.props.name} />
            </RowLayout>

            <RowLayout>
              <ItemLabel text="Telefon:" bold={true} italic={true} color="#686868" paddingRight="10px" />
              <ItemLabel text={this.props.phone} />
            </RowLayout>

            <RowLayout>
              <ItemLabel text="Ansatt nummer:" bold={true} italic={true} color="#686868" paddingRight="10px" />
              <ItemLabel text={this.props.employeeNumber} />
            </RowLayout>

            <RowLayout>
              <button onClick={() => this.approveUser()}>Godkjenn</button>
              <button onClick={() => this.denyUser()}>Avslå</button>
            </RowLayout>
          </ColumnLayout>
        </RowLayout>
      );
  }
}

/**
 * Maps redux state (appendingUsers) to this components equivalent props
 * @param {Redux state} state
 */
const mapStateToProps = state => ({
  onHandleResponse: state.appendingUsers.onHandleResponse
});

export default connect(
  mapStateToProps,
  { approveAppendingUser, denyAppendingUser }
)(ApproveUserItem);
