import React, { Component } from "react";

class RowLayout extends Component {
  render() {
    return (
      <div
        className={this.props.className ? this.props.className : ""}
        style={{
          display: "flex",
          flexDirection: this.props.flexDirection
            ? this.props.flexDirection
            : "row",
          width: this.props.stretchWidth
            ? "100%"
            : this.props.width
            ? this.props.width
            : "",
          height: this.props.stretchHeight
            ? "100%"
            : this.props.height
            ? this.props.height
            : "",
          paddingLeft: this.props.spaceLeft ? "10px" : "",
          paddingRight: this.props.spaceRight ? "10px" : "",
          paddingTop: this.props.spaceTop ? "10px" : "",
          paddingBottom: this.props.spaceBottom ? "10px" : "",
          marginTop: this.props.marginTop,
          marginRight: this.props.marginRight,
          marginBottom: this.props.marginBottom,
          marginLeft: this.props.marginLeft,
          borderLeft: this.props.borderLeft ? "1px solid black" : "",
          borderRight: this.props.borderRight ? "1px solid black" : "",
          borderBottom: this.props.borderBottom ? "1px solid black" : "",
          borderTop: this.props.borderTop ? "1px solid black" : "",
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default RowLayout;
