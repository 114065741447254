/**
 * All function in this module should collect data from database, aggregate and filter them to
 * be properly used in charts.
 * 
 * BarChart Exmaple data structure is:
 * 
 * preparedData = {
 *    xKey: "Kategori",
 *    yKey: "Antall",
 *    data: [] <--- push data to array { Kategori: key.toString(), Antall: data[key] }
 * }
 * 
 * Øyvind Johannessen
 */

import {
  getTotalProductInventoryFromDatabase,
  getActiveBorrowers
} from "./apiFunctions";

export default {
  /**
   * Returns BarChart prepared data.
   * 
   * The data is the count of products in each category.
   * 
   */
  productInventoryByCategory: async () => {
    const result = await getTotalProductInventoryFromDatabase();
    const data = await result.data.byCategory;

    let preparedData = {
      xKey: "Kategori",
      yKey: "Antall",
      data: []
    };

    for (var key in data) {
      if (data[key] !== 0) {
        preparedData.data.push({ Kategori: key.toString(), Antall: data[key] })
      }
    }

    return preparedData;
  },

  /**
   * Returns BarChart prepared data.
   * 
   * The data is the count of borrowed product in each category.
   */
  borrowedProductsByCategoryCount: async () => {
    const result = await getActiveBorrowers();

    let aggregation = {}

    let preparedData = {
      xKey: "Kategori",
      yKey: "Antall_Utlånt",
      data: []
    }
    
    result.forEach(borrower => {
      borrower.borrows.forEach(product => {
        const category = product.category;
        if(!aggregation[category]) aggregation[category] = 0;
        aggregation[category] += product.count;
      })
    })

    for (var key in aggregation) {
      preparedData.data.push({ Kategori: key.toString(), Antall_Utlånt: aggregation[key] })
    }

    return preparedData;
  },

  allBorrowedProducts: async () => {
    const result = await getActiveBorrowers();

    let allProducts = {};

    result.forEach(borrower => {
      borrower.borrows.forEach(product => {
        const category = product.category;
        if(!allProducts[category]) allProducts[category] = []
        allProducts[category].push(product);
      })
    })

    return allProducts;
  }
}