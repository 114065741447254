import React, { Component } from "react";
import { saveNewUserToDatabase } from "../../utils/apiFunctions";
import ColumnLayout from "../common/ColumnLayout";
import { InputField } from "../common/InputField";
import { UserAccessSelector } from "../common/UserAccessSelector";
import { ItemLabel } from "../common/ItemLabel";
import { SubmitButton } from "../common/SubmitButton";
import { verifyRegisterRequestKey } from "../../utils/apiFunctions"

class RegisterNewProfile extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      name: "",
      password: "",
      password2: "",
      employeeNumber: "",
      phone: "",
      borrower: true,
      errors: {},
      needKeyToEnter: false,
      accessKey: "",
      keyMessage: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.selectPermissionsEvent = this.selectPermissionsEvent.bind(this);
  }

  componentDidMount() {
    if(window.location.pathname === "/request-registration") {
      this.setState({
        needKeyToEnter: true
      })
    }
  }

  selectPermissionsEvent(e) {
    var option = document.getElementById("selectPermission").value;
    if (option === "borrower") {
      this.setState({
        borrower: true,
        generalLoaner: false,
        sysAdm: false,
        sysDev: false
      });
    }
    if (option === "generalLoaner") {
      this.setState({
        borrower: false,
        generalLoaner: true,
        sysAdm: false,
        sysDev: false
      });
    }
    if (option === "sysAdm") {
      this.setState({
        borrower: false,
        generalLoaner: false,
        sysAdm: true,
        sysDev: false
      });
    }
    if (option === "sysDev") {
      this.setState({
        borrower: false,
        generalLoaner: false,
        sysAdm: false,
        sysDev: true
      });
    }
  }

  onChange(e) {
    if(e.target.name === "accessKey") {
      this.setState({
        keyMessage: ""
      })
    }
    this.setState({ [e.target.name]: e.target.value });
  }

  verify = async () => {
    const { accessKey } = this.state
    const res = await verifyRegisterRequestKey(accessKey)
    if(res === true) {
      this.setState({
        keyMessage: "",
        accessKey: "",
        needKeyToEnter: false
      })
    } else {
      this.setState({
        keyMessage: "Feil nøkkel"
      })
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const newUser = {
      name: this.state.name,
      email: this.state.email,
      empNum: this.state.employeeNumber,
      phone: this.state.phone,
      generalLoaner: this.state.generalLoaner,
      sysAdm: this.state.sysAdm,
      sysDev: this.state.sysDev,
      borrower: this.state.borrower,
      ...( (this.state.password && this.state.password2) ? {
        password: this.state.password,
        password2: this.state.password2
      } : {})
    };

    saveNewUserToDatabase(this.props.routeName, newUser).then(res => {
      this.setState({ errors: res });
    });
  }

  render() {
    const { errors, needKeyToEnter, keyMessage } = this.state;
    const userAccessSelector =
      this.props.userAccessView === false ? (
        " "
      ) : (
        <UserAccessSelector
          name="category"
          id="selectPermission"
          onChange={this.selectPermissionsEvent.bind(this.onChange)}
        />
      );

      let content;

      if (needKeyToEnter) {
        content = <div>
            <div style={{textAlign: 'center'}}>
              <h1>Skriv inn hemmelig nøkkel for å registrere</h1>
              <h3>Denne kan du få utdelt av de som styrer utlånssystemet</h3>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <InputField 
                  pushRight="true" 
                  pushLeft="true" 
                  width="200px" 
                  placeholder="Nøkkel her"
                  name="accessKey"
                  value={this.state.accessKey}
                  onChange={this.onChange}/>
                  <button onClick={this.verify}>Verifiser</button>
              </div>
              <span style={{ color: "red" }}>{ keyMessage }</span>
            </div>
        </div>
      } else {
        content = <form
        className="align-center"
        onSubmit={this.onSubmit}
        style={{
          width:
            window.location.pathname === "/request-registration"
              ? "400px"
              : "100%"
        }}
      >
        <ColumnLayout stretchWidth={true}>
          {window.location.pathname === "/request-registration" ? (
            <h1 className="align-center"> Be om å få tilgang </h1>
          ) : (
            ""
          )}
          <InputField
            placeholder="Navn"
            type="text"
            name="name"
            value={this.state.name}
            onChange={this.onChange}
            minLength="2"
            maxLength="30"
            errorMessage={errors.name}
          />

          <InputField
            type="email"
            placeholder="Epost"
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            errorMessage={errors.email}
          />

          <InputField
            placeholder="Mobilnummer"
            type="tel"
            name="phone"
            value={this.state.phone}
            onChange={this.onChange}
          />

          <InputField
            placeholder="Ansattnummer"
            type="number"
            name="employeeNumber"
            value={this.state.employeeNumber}
            onChange={this.onChange}
          />
          {
            this.state.borrower != true ? ( <>
          <InputField
                      placeholder="Passord"
                      type="password"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChange}
                      minLength="6"
                      maxLength="30"
                      errorMessage={errors.password}
                    />

                    <InputField
                      placeholder="Gjenta passord"
                      type="password"
                      name="password2"
                      value={this.state.password2}
                      onChange={this.onChange}
                      minLength="6"
                      maxLength="30"
                      errorMessage={errors.password2}
                    />
          </> ) : ""
          }
          {userAccessSelector}
          <SubmitButton text="Registrer Bruker" />
        </ColumnLayout>
      </form>
      }

    return (
      <div style={{ width: '100%' }}>
        { content }
      </div>
    );
  }
}

export default RegisterNewProfile;
