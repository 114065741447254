import React, { Component } from "react";
import Moment from "react-moment";
import swal from "sweetalert2";
import { deleteAChangeLogFromDatabase } from "../../utils/apiFunctions";
import "./css/changelog-item.css";
class ChangelogItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creationDate: this.props.date
    };
    this.removeChangelogItem = this.removeChangelogItem.bind(this);
  }
  removeChangelogItem(id) {
    swal({
      title: "Slette oppføring??",
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText: "Ikke slett",
      confirmButtonText: "Slett oppføring"
    }).then(confirm => {
      if (confirm.value) {
        deleteAChangeLogFromDatabase(id);
        this.props.removeMe(id);
      }
    });
  }

  render() {
    return (
      <div>
        <div className="changelog-item-container">
          <div className="changelog-item-container-header">
            <i
              className="fa fa-times changelog-item-remove"
              onClick={() => {
                this.removeChangelogItem(this.props.id);
              }}
            />
            <small className="changelog-item-date">
              <Moment format="[Den ]DD/MM/YYYY [Kl: ]HH:MM:SS">
                {this.state.creationDate}
              </Moment>
            </small>
          </div>
          <div className="changelog-item-small-cont">
            <div className="changelog-item-list">
              <h1 className="changelog-item-h1">{this.props.header}</h1>
            </div>
            <p className="changelog-item-desc">{this.props.desc}</p>
            <p className="changelog-item-note">{this.props.note}</p>
          </div>
        </div>
      </div>
    );
  }
}
export default ChangelogItem;
