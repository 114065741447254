import React, { Component } from 'react';
import BorrowItem from "../common/BorrowItem";
import { redeliverBorrowedProduct, redeliverListOfProducts, redeliveryRequestByEmail } from "../../utils/apiFunctions";
import { ItemLabel } from "../common/ItemLabel";
import ColumnLayout from './ColumnLayout';
import Tooltip from './Tooltip';

/**
 * User ID for this schema is passed in as prop -> this.props.userId
 */
class BorrowSchemaEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedItem: undefined,
            countToRemove: undefined,
            emailResponse: undefined
        }
    }

    redeliverMany = async () => {
        const { items } = this.props;

        let toBeDelivered = {
            userId: this.props.userId,
            products: []
        }

        items.forEach(item => {
            toBeDelivered.products.push({ id: item._id, count: item.count })
        })

        await redeliverListOfProducts(toBeDelivered);

        this.updateSchema();
    }

    returnProduct = () => {
        redeliverBorrowedProduct({
            userId: this.props.userId,
            prodId: this.state.selectedItem,
            countToRemove: this.state.countToRemove
        }).then(() => {
            this.updateSchema()
        })
    }

    updateSchema = () => {
        if (this.props.parent) {
            this.props.parent.updateUserSchema();
        }
    }

    sendRedeliverEmailNotification = async () => {
        const data = await redeliveryRequestByEmail(this.props.userId);
        console.log(data)
        this.setState({
            emailResponse: data.message
        })

    }

    render() {
        return (
            <div className={this.props.className ? this.props.className : ""}>
                {this.props.items ? this.props.items.map(product => (
                    <Tooltip text={`${product.title}: Trykk for tilbakelevering.`}>
                        <BorrowItem
                            id={product._id}
                            key={product._id}
                            name={product.title}
                            prodNum={product.productNum}
                            imgSrc={product.img}
                            count={product.count}
                            category={product.category}
                            loanStart={product.loanStart}
                            loanEnd={product.loanEnd}
                            parent={this}
                            returnProduct={this.returnProduct}
                            className="HoverItem pointer"
                        />
                    </Tooltip>
                )) : ""}

                <ColumnLayout>
                    {(this.props.items === undefined || this.props.items.length === 0) && this.props.userId ? <ItemLabel text="Låner ingen produkter" color="#686868" /> : this.props.items ? <button onClick={this.redeliverMany}>Tilbakelever alle</button> : ""}
                    {(this.props.items === undefined || this.props.items.length === 0) && this.props.userId ? "" : this.props.items ? <button onClick={this.sendRedeliverEmailNotification}>Send påminnelse om tilbakelevering med epost</button> : ""}

                    {this.state.emailResponse ? this.state.emailResponse : ""}
                </ColumnLayout>

            </div>
        );
    }
}

export default BorrowSchemaEditor;