import React from "react";

const style = {
    backgroundColor: "rgba(0,0,0,0.0)",
    padding: "12px 20px",
    margin: "8px 0",
    display: "inline-block",
    border: "none",
    borderBottom: "1px solid black",
    outline: "none"
}

export const UserAccessSelector = (props) => (
    <select style={style} name={props.name} id={props.id} onChange={props.onChange}>
        <option value="borrower">Velg Brukertilgang</option>
        <option value="borrower">Låntaker</option>
        <option value="generalLoaner">Utlåner</option>
        <option value="sysAdm">System Administrator</option>
        <option value="sysDev">System Utvikler</option>
    </select>);
