import React, { Component } from 'react';

class TrayBar extends Component {
    render() {
        return (<div style={{ backgroundColor: "#1A1A1A" }}>
            {this.props.trayHandles.map((trayHandle, index) => (<button className="swipeInLeftAnimation" style={{ margin: "0" }} onClick={() => this.props.onClick(index)} key={index}>{trayHandle}</button>))}
        </div>);
    }
}

export default TrayBar;
