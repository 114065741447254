import React, { Component } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { loadTemplates } from "../../actions/templateActions";
import {
  deleteAProductFromDatabase,
  forceDeleteAProductFromDatabase,
  getAllUsersBorrowingAProduct,
  getTemplateByCategory,
  getProductById
} from "../../utils/apiFunctions";
import KeyValueFieldEditor from "../common/KeyValueFieldEditor";
import { getImageFromDatabase } from "../../utils/apiFunctions";
import RowLayout from "../common/RowLayout";
import { ItemImage } from "../common/ItemImage";
import ColumnLayout from "../common/ColumnLayout";

const swrc = withReactContent(swal);

class ProductEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      handle: "",
      productNum: "",
      title: "",
      description: "",
      huntingLicense: "",
      boat: "",
      wetCard: "",
      climbingLicense: "",
      classBE: "",
      category: "",
      img: " ",
      value: "",
      count: "",
      creationDate: "",
      isVisible: true,
      isLoading: true,
      template: "",
      borrowedByUsers: [],
      image: "https://dummyimage.com/180x180/fff/000000&text=No+Image"
    };

    this._setTemplateName = this._setTemplateName.bind(this);
    this.setImage = this.setImage.bind(this);
    this.setHandle = this.setHandle.bind(this);
  }

  _viewBorrowers() {
    const { borrowedByUsers } = this.state;
    swrc.fire({
      title: `Låntakere av ${this.state.title}`,
      html: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {borrowedByUsers.map(schema => (
            <span style={{ margin: "5px" }}>{schema.name}</span>
          ))}
        </div>
      )
    });
  }

  _deleteProduct() {
    const length = this.state.borrowedByUsers.length;
    const text = `Systemet registerer at dette produktet er utlånt til ${length} låntakere. Sletting av dette produktet vil oppfattes som tvunget. Dette vil si at systemet sletter selve produktet (${this.state.title}) samtidig som produktet slettes fra låneskjemaet til lånetakerne. Dette vil medføre at produkter som ikke er levert inn, i dette tilfellet ${this.state.title}, ikke kan registeres i tilbakelevering.`;

    if (length !== 0) {
      swrc
        .fire({
          title: "Advarsel",
          html: (
            <div>
              <p>{text}</p>
              <p>
                Et alternativ er å be disse låntakerne returnere produktene før
                sletting. På denne måten vil dere være sikker på å ikke miste
                dem.
              </p>
              <p>
                For å utføre sletting:
                <ul>
                  <li>Trykk på knappen som heter 'Tvunget Slett'</li>
                  <li>Skriv inn 'slett' i boksen som vises</li>
                  <li>Trykk 'OK'</li>
                </ul>
              </p>
            </div>
          ),
          type: "warning",
          showCancelButton: true,
          cancelButtonText: "Avbryt",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Tvunget Slett",
          confirmButtonColor: "#d33"
        })
        .then(res => {
          if (res.value === true) {
            swal
              .fire({
                title: "Bekreft ved å skrive 'slett'",
                input: "text",
                showCancelButton: true,
                cancelButtonText: "Avbryt"
              })
              .then(res => {
                if (res.value === "slett") {
                  forceDeleteAProductFromDatabase(this.state.id)
                    .then(res => {
                      if (res.status === 202) {
                        swal.fire({
                          title: `${this.state.title} er slettet`,
                          type: "success",
                          timer: 2000,
                          showConfirmButton: false
                        });
                      }
                    })
                    .catch(err => {
                      swal.fire({
                        title: `En feil oppstod`,
                        type: "error",
                        timer: 2000,
                        showConfirmButton: false
                      });
                      console.log(err);
                    });
                }
              });
          }
        });
    } else {
      swal
        .fire({
          title: `Er du sikker på at du vil slette produktet ${this.state.title}`,
          type: "warning",
          showCancelButton: true,
          cancelButtonText: "Avbryt",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Slett",
          confirmButtonColor: "#d33"
        })
        .then(res => {
          if (res.value === true) {
            deleteAProductFromDatabase(this.state.id)
              .then(res => {
                if (res.status === 202) {
                  swal.fire({
                    title: `${this.state.title} er slettet`,
                    type: "success",
                    timer: 2000,
                    showConfirmButton: false
                  });
                }
              })
              .catch(err => {
                swal.fire({
                  title: `En feil oppstod`,
                  type: "error",
                  timer: 2000,
                  showConfirmButton: false
                });
                console.log(err);
              });
          }
        });
    }
  }

  _setTemplateName(tempName) {
    getTemplateByCategory(tempName).then(res => {
      this.setState({ template: res.data });
    });
  }

  componentDidMount() {
    this.props.loadTemplates();
    getProductById(this.props.id.toString()).then(res => {
      var product = res.data;
      var licences = res.data.userDemands;

      if (product.img !== " ") {
        getImageFromDatabase(product.img).then(res => {
          this.setState({
            image: "data:image/png;base64," + res
          });
        })
      }

      this.setState(
        {
          id: product._id,
          handle: product.handle,
          productNum: product.productNum,
          title: product.title,
          description: product.description,
          huntingLicense: licences.huntingLicense,
          boat: licences.boat,
          wetCard: licences.wetCard,
          climbingLicense: licences.climbingLicense,
          classBE: licences.classBE,
          category: product.category,
          img: product.img,
          value: product.value,
          count: product.count,
          creationDate: product.creationDate
        },
        () => {
          getTemplateByCategory(this.state.category).then(res => {
            this.setState({ template: res.data }, () => {
              getAllUsersBorrowingAProduct(this.state.id).then(res => {
                this.setState({ borrowedByUsers: res.data }, () => {
                  this.setState({ isLoading: false });
                });
              });
            });
          });
        }
      );
    });
  }

  setImage(imgUrl) {
    this.setState({
      img: imgUrl
    });
  }

  setHandle(handle) {
    this.setState({
      handle: handle
    });
  }

  render() {
    return (
      <div>
        {this.state.isVisible && !this.state.isLoading ? (
          <ColumnLayout spaceRight={true}>
            <RowLayout stretch={true}>
              <ItemImage image={this.state.image} />
            </RowLayout>
            {this.state.borrowedByUsers.length > 0 ? (
              <em style={{ margin: "auto", padding: "5px" }}>
                Det er anbefalt å få tilbakelevert produkter før man gjør
                endringer på dem.
              </em>
            ) : (
                ""
              )}
            <KeyValueFieldEditor
              keyName="Håndtak"
              keyValueObject={{ handle: this.state.handle }}
              value={this.state.handle}
              canEdit={false}
              booleanValue={false}
              theId={this.state.id}
              parentEditorName="productEditor"
              isHandle={true}
            />
            {this.state.template.title ? (
              <KeyValueFieldEditor
                keyName="Tittel"
                keyValueObject={{ title: this.state.title }}
                value={this.state.title}
                canEdit={true}
                booleanValue={false}
                theId={this.state.id}
                parentEditorName="productEditor"
                setHandle={this.setHandle}
              />
            ) : (
                ""
              )}
            {this.state.template.productNum ? (
              <KeyValueFieldEditor
                keyName="Produkt Nummer"
                keyValueObject={{ productNum: this.state.productNum }}
                value={this.state.productNum}
                canEdit={true}
                booleanValue={false}
                theId={this.state.id}
                parentEditorName="productEditor"
                setHandle={this.setHandle}
              />
            ) : (
                ""
              )}
            {this.state.template.description ? (
              <KeyValueFieldEditor
                keyName="Beskrivelse"
                keyValueObject={{ description: this.state.description }}
                value={this.state.description}
                canEdit={true}
                booleanValue={false}
                theId={this.state.id}
                parentEditorName="productEditor"
              />
            ) : (
                ""
              )}
            {this.state.template["userDemands"].huntingLicense ? (
              <KeyValueFieldEditor
                keyName="Jegerprøven"
                keyValueObject={{ huntingLicense: this.state.huntingLicense }}
                value={this.state.huntingLicense}
                canEdit={true}
                booleanValue={true}
                theId={this.state.id}
                parentEditorName="productEditor"
              />
            ) : (
                ""
              )}
            {this.state.template["userDemands"].boat ? (
              <KeyValueFieldEditor
                keyName="Båtførerprøven"
                keyValueObject={{ boat: this.state.boat }}
                value={this.state.boat}
                canEdit={true}
                booleanValue={true}
                theId={this.state.id}
                parentEditorName="productEditor"
              />
            ) : (
                ""
              )}
            {this.state.template["userDemands"].wetCard ? (
              <KeyValueFieldEditor
                keyName="Våt Sertifikat"
                keyValueObject={{ wetCard: this.state.wetCard }}
                value={this.state.wetCard}
                canEdit={true}
                booleanValue={true}
                theId={this.state.id}
                parentEditorName="productEditor"
              />
            ) : (
                ""
              )}
            {this.state.template["userDemands"].climbingLicense ? (
              <KeyValueFieldEditor
                keyName="Brattkort"
                keyValueObject={{
                  climbingLicense: this.state.climbingLicense
                }}
                value={this.state.climbingLicense}
                canEdit={true}
                booleanValue={true}
                theId={this.state.id}
                parentEditorName="productEditor"
              />
            ) : (
                ""
              )}
            {this.state.template["userDemands"].classBE ? (
              <KeyValueFieldEditor
                keyName="Klasse BE"
                keyValueObject={{ classBE: this.state.classBE }}
                value={this.state.classBE}
                canEdit={true}
                booleanValue={true}
                theId={this.state.id}
                parentEditorName="productEditor"
              />
            ) : (
                ""
              )}
            <KeyValueFieldEditor
              keyName="Kategori"
              keyValueObject={{ category: this.state.category }}
              value={this.state.category}
              canEdit={true}
              booleanValue={true}
              categoryValue={true}
              theId={this.state.id}
              parentEditorName="productEditor"
              categorySelector={true}
              templateNames={this.props.templateNames}
              setTemplateNameInParent={this._setTemplateName}
            />
            {this.state.template.img ? (
              <KeyValueFieldEditor
                keyName="Bilde"
                keyValueObject={{ img: this.state.img }}
                value={this.state.img}
                canEdit={true}
                booleanValue={false}
                theId={this.state.id}
                parentEditorName="productEditor"
                imageSelector={true}
                setImage={this.setImage}
              />
            ) : (
                ""
              )}
            {this.state.template.value ? (
              <KeyValueFieldEditor
                keyName="Verdi"
                keyValueObject={{ value: this.state.value }}
                value={this.state.value}
                canEdit={true}
                booleanValue={false}
                numberField={true}
                theId={this.state.id}
                parentEditorName="productEditor"
              />
            ) : (
                ""
              )}
            {this.state.template.count ? (
              <KeyValueFieldEditor
                keyName="Antall"
                keyValueObject={{ count: this.state.count }}
                value={this.state.count}
                canEdit={true}
                booleanValue={false}
                numberField={true}
                theId={this.state.id}
                parentEditorName="productEditor"
              />
            ) : (
                ""
              )}
            <KeyValueFieldEditor
              keyName="Opprettet"
              value={
                <Moment format="DD/MM/YYYY">
                  {new Date(this.state.creationDate)}
                </Moment>
              }
              canEdit={false}
              booleanValue={false}
              theId={this.state.id}
              parentEditorName="productEditor"
            />
            <span onClick={() => this._viewBorrowers()} style={
              { margin: "10px 0 0 0" }
            }>
              Det er {this.state.borrowedByUsers.length} personer som låner
              dette produktet
              </span>
            <button id="delete-button" onClick={() => this._deleteProduct()} style={
              { margin: "10px 0 0 0" }
            }>
              Slett {this.state.title}
            </button>
          </ColumnLayout>
        ) : (
            ""
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  templateNames: state.template.map(tempName => tempName.category)
});

export default connect(
  mapStateToProps,
  { loadTemplates }
)(ProductEditor);
