import axios from 'axios';

// Import types
import { LOAD_TEMPLATES, GET_ERRORS } from './types'

/* Functions for template api */

// Load Templates
export const loadTemplates = () => dispatch => {
  axios.get('/api/product-category-template/get-temp-names')
    .then(res => {
      if(res) {
        dispatch({
          type: LOAD_TEMPLATES,
          payload: res.data
        })
      }
    }).catch(err => {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
    })
}
