import React, { Component } from "react";
import swal from "sweetalert2";
import { deleteTodoNoteFromDatabase } from "../../../utils/apiFunctions";
import RowLayout from "../../common/RowLayout";
import ColumnLayout from "../../common/ColumnLayout";
import { ItemLabel } from "../../common/ItemLabel";

class TodoItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.removeItem = this.removeItem.bind(this);
  }
  removeItem(id) {
    swal({
      title: "Slette gjøremål?",
      text: "Dette gjøremålet blir nå slettet.",
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonColor: "DFF2BF",
      cancelButtonColor: "FEEFB3",
      cancelButtonText: "Avbryt",
      confirmButtonText: "Slett gjøremål"
    }).then(confirm => {
      if (confirm.value) {
        deleteTodoNoteFromDatabase(id);
        this.props.removeMe(id);
      }
    });
  }

  render() {
    const dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    var creationDate = new Date(this.props.date);
    creationDate = creationDate.toLocaleDateString("no-NB", dateOptions);
    return (
      <ColumnLayout className="todo-item-container dropShadow" stretchhWidth={true} marginBottom="20px">
        <RowLayout stretchhWidth={true}>
          <i
            className="fa fa-times todo-item-remove pointer iconButtonActive"
            onClick={() => {
              this.removeItem(this.props.id);
            }}
            style={{ marginRight: "auto" }}
          />
          <ItemLabel text={creationDate} />
        </RowLayout>

        <RowLayout spaceTop={true} spaceBottom={true} borderBottom={true}>
          <ItemLabel text={this.props.header} bold={true} />
        </RowLayout>

        <RowLayout spaceTop={true} spaceBottom={true}>
          <ItemLabel text={this.props.desc} />
        </RowLayout>

        <RowLayout>
          <ItemLabel
            text={this.props.note}
            italic={true}
            color="#686868" />
        </RowLayout>

      </ColumnLayout>
    );
  }
}

export default TodoItem;
