import "moment/locale/it";
import React, { Component } from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import Moment from "react-moment";

/**
 * A component that represents a DayPicker for selecting a range from a day to a day
 * This components state holds the actual period in the variables 'from' and 'to'.
 * To pass the period variabled to another component, set the sendPeriodToShoppingCartState prop to
 * a function that again sets the variables in another component. This means that the function that is passed
 * to this components prop, is being called by this component on change.
 * Author: Øyvind Johannessen
 * Version: 1.0
 */
class PeriodPicker extends Component {
  static defaultProps = {
    numberOfMonths: 2
  };

  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      from: undefined,
      to: undefined
    };
  }

  handleDayClick(day) {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range, () => this._sendPeriodToShoppingCart());
    console.log(range)
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  /**
   * Used to send this components state variables 'from' and 'to', to another component
   * Author: Øyvind Johannessen
   * Version: 1.0
   */
  _sendPeriodToShoppingCart() {
    this.props.sendPeriodToShoppingCartState(this.state.from, this.state.to);
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    return (
      <div className="RangeExample">
        <div style={{ display: "flex", flexDirection: "column" }}>
          {from ? (
            <div>
              <b>fra </b>
              <Moment format="DD/MM/YYYY">
                {new Date(from)}
              </Moment>
            </div>
          ) : (
            " "
          )}
          {to ? (
            <div>
              <b> til </b>
              <Moment format="DD/MM/YYYY">
                {new Date(to)}
              </Moment>
            </div>
          ) : (
            " "
          )}
        </div>
        <DayPicker
          className="Selectable"
          numberOfMonths={this.props.numberOfMonths}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
        />
      </div>
    );
  }
}

export default PeriodPicker;
