import React, { Component } from "react";
import RegisterNewProduct from "./RegisterNewProduct";
import ColumnLayout from "../common/ColumnLayout";
import ProductItem from "../common/ProductItem";
import { ItemLabel } from "../common/ItemLabel";
import GlobalDataController from "../../utils/GlobalDataController";
import { isSysDevSysAdmOrLender } from "../../utils/conditionalRenderFunctions";
import { SearchInputField } from "../common/SearchInputField";
import { SearchButton } from "../common/SearchButton";
import { CategorySelector } from "../common/CategorySelector";
import ContentContainer from "../common/ContentContainer";
import RowLayout from "../common/RowLayout";

import "./css/product-container-style.css";
import ProductEditor from "./ProductEditor";
import Tooltip from "../common/Tooltip";

class ProductContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      productId: undefined,
      productName: undefined
    }
  }

  setIsLoading() {
    const { isLoading } = this.state;
    this.setState({
      isLoading: isLoading ? false : true
    })
  }

  render() {
    const registerNewProduct = isSysDevSysAdmOrLender() ?
      <RegisterNewProduct /> :
      "";

    const productName = this.state.productName ? this.state.productName : "Produkt";

    return (
      <ContentContainer>
        <RowLayout stretchWidth={true} stretchHeight={true}>

          <ColumnLayout stretchWidth={true} spaceRight={true} className="align-center">
            <ItemLabel text="Nytt Produkt" bold={true} marginBottom="10px" />
            {registerNewProduct}
          </ColumnLayout>

          <ColumnLayout stretchWidth={true} stretchHeight={true} borderLeft={true} spaceRight={true} spaceLeft={true} className="align-center">
            <ItemLabel text={"Produkt"} bold={true} marginBottom="10px" />
            <ColumnLayout stretchWidth={true}>
              <SearchInputField searchType="product" placeholder="Søk etter produkt..." onEnter={() => {
                this.setIsLoading();
              }} />
              <CategorySelector />
              <SearchButton callback={() => { this.setIsLoading() }} searchType="product" text="Søk" />
            </ColumnLayout>

            <ColumnLayout spaceRight={true} spaceLeft={true} scrollable={true}>
              {GlobalDataController.productsBySearch.map(product => (
                <Tooltip text={`${product.title}: Trykk for å redigere produkt.`}>
                  <ProductItem
                    growValue="1"
                    key={product._id}
                    name={product.title}
                    category={product.category}
                    count={product.count}
                    id={product._id}
                    imgSrc={product.img}
                    includeAddButton={false}
                    onClickView="productEditor"
                    className="ItemHover pointer"
                    parent={this}
                  />
                </Tooltip>
              ))}
            </ColumnLayout>
          </ColumnLayout>

          <ColumnLayout stretchWidth={true} stretchHeight={true} borderLeft={true} spaceRight={true} spaceLeft={true} className="align-center">
            <ItemLabel text={"Rediger " + productName} bold={true} marginBottom="10px" />
            <ColumnLayout scrollable={true}>
              {this.state.productId ? <ProductEditor id={this.state.productId} /> : ""}
            </ColumnLayout>
          </ColumnLayout>
        </RowLayout>
      </ContentContainer>
    );
  }
}

export default ProductContainer;
