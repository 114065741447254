import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert2";
import { getAppendingUsers } from "../../actions/appendingUsersAction";
import { saveNewTodoNoteToDatabase } from "../../utils/apiFunctions";
import AppendingUsers from "../common/AppendingUsers";
// CSS
import "./css/dashboard-style.css";
import TodoList from "./dashboard-components/TodoList";
import ContentContainer from "../common/ContentContainer";
import ColumnLayout from "../common/ColumnLayout";
import { ItemLabel } from "../common/ItemLabel";
import RowLayout from "../common/RowLayout";
import UserItem from "../common/UserItem";
import { getActiveBorrowers } from "../../utils/apiFunctions";
import BarChartComponent from "../common/BarChartComponent";
import ChartDataFunctions from "../../utils/ChartDataFunctions";
import TrayContainer from "../common/TrayContainer";
import BorrowdProductsDataTable from "../common/BorrowedProductsDataTable";
import Tooltip from "../common/Tooltip";
import Loading from "../common/Loading";
import Footer from "../common/Footer";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showActiveBorrowers: false,
      activeBorrowers: [],
      countByCategoryDataBarChart: undefined,
      borrowedProductsByCategoryBarChart: undefined,
      allBorrowedProducts: undefined,
      dataIsLoading: true
    };

    this.child = React.createRef();

    this.newTodoModal = this.newTodoModal.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    await this.props.getAppendingUsers();
    await this.setPastBorrowers();
    const countByCategoryDataBarChart = await ChartDataFunctions.productInventoryByCategory();
    const borrowedProductsByCategoryBarChart = await ChartDataFunctions.borrowedProductsByCategoryCount();
    const allBorrowedProducts = await ChartDataFunctions.allBorrowedProducts();

    this.setState({
      countByCategoryDataBarChart: countByCategoryDataBarChart,
      borrowedProductsByCategoryBarChart: borrowedProductsByCategoryBarChart,
      allBorrowedProducts: allBorrowedProducts,
      dataIsLoading: false
    })

  }

  setPastBorrowers = async () => {
    const activeBorrowers = await getActiveBorrowers();
    this.setState({
      activeBorrowers: activeBorrowers
    });
  }

  componentWillUnmount() {
    // Update Redux state to database state of appending users
    this.props.getAppendingUsers();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  newTodoModal() {
    swal({
      title: "Nytt gjøremål",
      html:
        "<span>Fyll inn feltene under</span>" +
        '<input id="swal-input1" class="swal2-input" placeholder="Tittel">' +
        '<input id="swal-input2" class="swal2-input" placeholder="Beskrivelse">' +
        '<input id="swal-input3" class="swal2-input" placeholder="Notat">',
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText: "Avbryt",
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
          document.getElementById("swal-input3").value
        ];
      }
    }).then(preConfirm => {
      if (!preConfirm.dismiss) {
        const todoData = {
          title: preConfirm.value[0],
          desc: preConfirm.value[1],
          note: preConfirm.value[2]
        };
        saveNewTodoNoteToDatabase(todoData).then(() => {
          this.child.current.componentDidMount();
        });
      }
    });
  }

  /*
     Function to collect the users that have past due dates
     */
  pastBorrowers = () => {
  }

  getHeadersFromBorrowedProducts = () => {
    const { allBorrowedProducts } = this.state;

    let headers = []

    for (var key in allBorrowedProducts) {
      headers.push(key.toString());
    }

    return headers;
  }

  render() {
    const { activeBorrowers, countByCategoryDataBarChart, borrowedProductsByCategoryBarChart, allBorrowedProducts, dataIsLoading } = this.state;
    return (
      <ContentContainer className="dashboard-main-div fastExtendAnimation">
        <ColumnLayout stretchWidth={true} stretchHeight={true}>

          <RowLayout stretchWidth={true} stretchHeight={true} >
            <ColumnLayout
              stretchWidth={true}
              stretchHeight={true}
              spaceRight={true}
              className="align-center todo-item-list"
            >
              <ItemLabel text="Gjøremål" bold={true} marginBottom="10px" />
              <TodoList ref={this.child}>
                <button
                  style={{
                    padding: "0px",
                    margin: "0px"
                  }}
                  className="web add-btn fa"
                  onClick={this.newTodoModal}
                >
                  <i className="fas fa-plus-circle iconButtonActive" />
                </button>
              </TodoList>
            </ColumnLayout>

            <ColumnLayout
              stretchWidth={true}
              stretchHeight={true}
              spaceRight={true}
              spaceLeft={true}
              borderLeft={true}
              scrollable={true}
              className="align-center"
            >
              <ItemLabel
                text="Brukere til godkjenning"
                bold={true}
                marginBottom="10px"
              />
              {this.props.appendingUsers ? (
                <AppendingUsers appendingUsersList={this.props.appendingUsers} />
              ) : (
                  <ItemLabel
                    text="Ingen brukere til godkjenning"
                    italic={true}
                    color="#686868"
                  />
                )}
            </ColumnLayout>
            <ColumnLayout
              stretchWidth={true}
              stretchHeight={true}
              borderLeft={true}
              className="align-center"
            >
              <ColumnLayout>
                <ItemLabel
                  text="Ikke tilbakelevert utstyr"
                  bold={true}
                  marginBottom="10px"
                />
              </ColumnLayout>
              <ColumnLayout scrollable={true}>
                {activeBorrowers.map(user => {
                  let missedDueDates = false;
                  user.borrows.forEach(product => {
                    if (new Date(product.loanEnd) < new Date(new Date().toDateString())) {
                      missedDueDates = true;
                    }
                  });
                  if (missedDueDates) {
                    return (
                      <Tooltip text={`${user.name}: Klikk på bilde for å vise låneskjema.`}>
                        <UserItem
                          className="pointer"
                          color="red"
                          name={user.name + " Låner " + user.borrows.length + " produkt."}
                          schemaTitle={user.name}
                          id={user.user_id}
                          borrowModalSchemaOnClick={true}
                          setPastBorrowers={this.setPastBorrowers}
                        />
                      </Tooltip>
                    );
                  }
                })}
              </ColumnLayout>

            </ColumnLayout>
          </RowLayout>

          {!dataIsLoading ? <TrayContainer trayHandles={[
            "Totalt antall produkter per kategori",
            "Antall utlånte produkter per kategori",
            "Alle produkter som er utlånt"
          ]} trayList={[

            <BarChartComponent
              data={countByCategoryDataBarChart.data}
              xKey={countByCategoryDataBarChart.xKey}
              yKey={countByCategoryDataBarChart.yKey} />,

            <BarChartComponent
              data={borrowedProductsByCategoryBarChart.data}
              xKey={borrowedProductsByCategoryBarChart.xKey}
              yKey={borrowedProductsByCategoryBarChart.yKey} />,

            <BorrowdProductsDataTable headers={this.getHeadersFromBorrowedProducts()} data={allBorrowedProducts} />

          ]} /> : <Footer><Loading textColor="#ddd" /></Footer>}


        </ColumnLayout>

      </ContentContainer>
    );
  }
}

/**
 * Maps redux state (appendingUsers) to this components equivalent props
 * @param {Redux state} state
 */
const mapStateToProps = state => ({
  appendingUsers: state.appendingUsers.users
});

export default connect(mapStateToProps, { getAppendingUsers })(Dashboard);
