import {
  SET_CART_USER,
  SET_CART_ITEMS,
  REMOVE_CART_USER,
  CLEAR_CART,
  REMOVE_CART_ITEM
} from "../actions/types";

const initialState = {
  user: {
    name: " ",
    id: " "
  },
  items: [
    // [ {id: ' ', count: 2} ]
  ]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CART_USER:
      return {
        ...state,
        user: Object.assign({}, action.payload)
      };
    case SET_CART_ITEMS:
      return {
        ...state,
        items: Object.assign([], action.payload)
      };
    case REMOVE_CART_USER:
      return {
        ...state,
        user: Object.assign({}, action.payload)
      };
    case CLEAR_CART:
      return {
        ...state,
        items: Object.assign([], action.payload)
      };
    case REMOVE_CART_ITEM:
      return {
        ...state,
        items: Object.assign([], action.payload)
      };
    default:
      return state;
  }
}
