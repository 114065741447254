import React, { Component } from "react";
import KeyValueFieldEditor from "../common/KeyValueFieldEditor";
import Axios from "axios";
import Moment from "react-moment";
import { ItemImage } from "../common/ItemImage";
import ColumnLayout from "../common/ColumnLayout";
import Loading from "../common/Loading";
import { ItemLabel } from "../common/ItemLabel";
import RowLayout from "../common/RowLayout";
import { EditButton } from "../common/EditButton";
import { CheckButton } from "../common/CheckButton";

export default class UserEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      employeeNumber: "",
      email: "",
      phone: "",
      userAccess: {},
      avatar: undefined,
      creationDate: "",
      isLoading: true,
      editAccessView: false,
      accessValue: "",
      origAccValue: ""
    };

    this.updateUserAccess = this.updateUserAccess.bind(this);
  }

  componentDidMount() {
    Axios.get("/api/users/userById/" + this.props.id.toString()).then(res => {
      var user = res.data;
      var userAccess = res.data.userAccess.admin;

      this.setState(
        {
          id: user.id,
          name: user.name,
          employeeNumber: user.employeeNumber,
          email: user.email,
          phone: user.phone,
          userAccess: userAccess,
          avatar: user.avatar,
          creationDate: user.creationDate,
        },
        () => {
          this.setState({
            isLoading: false,
            origAccValue: this._getUserAccessString(),
            accessValue: this._getUserAccessString(),
          });
        }
      );
    });
  }

  setUserAccess = (e) => {
    this.setState({
      accessValue: e.target.value
    })
  }

  _getUserAccessString = () => {
    const { userAccess } = this.state;

    for (var key in userAccess) {
      if (userAccess[key]) return key.toString();
    }
  }

  _getUserAccessStatus() {
    const { userAccess } = this.state;
    let options = [];
    for (var key in userAccess) {
      if (userAccess[key] == true) {
        options.push(
          <option value={key} selected="selected">
            {this._getUserAccessNorwegianNames(key.toString())}
          </option>
        );
      } else {
        options.push(
          <option value={key}>
            {this._getUserAccessNorwegianNames(key.toString())}
          </option>
        );
      }
    }
    return options;
  }

  _getUserAccessNorwegianNames(key) {
    switch (key) {
      case "sysDev":
        return "System Utvikler";
      case "sysAdm":
        return "System Administrator";
      case "borrower":
        return "Låntaker";
      case "generalLender":
        return "Utlåner";
    }
  }

  editAccess = () => {
    const { editAccessView, origAccValue } = this.state;
    this.setState({
      editAccessView: editAccessView ? false : true,
      origAccValue: this._getUserAccessString(),
      accessValue: this._getUserAccessString(),
    })
  }

  /**
   * Updates a users access to the system.
   */
  updateUserAccess() {
    const { userAccess, accessValue } = this.state;

    Axios.post("/api/users/updateUser/" + this.state.id.toString(), {
      [accessValue]: true
    }).catch(err => {
      console.log(err);
    });

    for(var key in userAccess) {
      if (key === accessValue) userAccess[key] = true;
      else userAccess[key] = false;
    }

    this.setState({
      userAccess: userAccess
    }, () => {
      this.editAccess()
    })
  }

  render() {
    // Get the state
    const {
      id,
      name,
      employeeNumber,
      email,
      phone,
      avatar,
      creationDate,
      isVisible,
      isLoading
    } = this.state;
    return (

      <div>
        {isLoading ? <Loading margin="10px auto 10px auto" /> : <ColumnLayout                      
          className="swipeUpAnimation"
          spaceLeft={true}
          spaceRight={true}
        >
          <KeyValueFieldEditor
            keyName="Navn"
            keyValueObject={{ name: { name } }}
            value={name}
            canEdit={true}
            booleanValue={false}
            theId={id}
            parentEditorName="userEditor"
          />
          <KeyValueFieldEditor
            keyName="AnsattNr"
            keyValueObject={{ employeeNumber: { employeeNumber } }}
            value={employeeNumber}
            canEdit={true}
            booleanValue={false}
            theId={id}
            parentEditorName="userEditor"
          />
          <KeyValueFieldEditor
            keyName="Epost"
            keyValueObject={{ email: { email } }}
            value={email}
            canEdit={true}
            booleanValue={false}
            theId={id}
            parentEditorName="userEditor"
          />
          <KeyValueFieldEditor
            keyName="Telefon"
            keyValueObject={{ phone: { phone } }}
            value={phone}
            canEdit={true}
            booleanValue={false}
            theId={id}
            parentEditorName="userEditor"
          />
          <RowLayout
            borderBottom={true}
            stretchWidth={true}
            spaceTop={true}
            spaceBottom={true}
          >
            <RowLayout width="50%">
              <ItemLabel
                text="Tilgang:"
                italic={true}
                color="#111"
                bold={true} />
            </RowLayout>

            <RowLayout width="100%">
              {this.state.editAccessView ?

                <select
                  className="categorySelectorNew"
                  name="newUserAccess"
                  onChange={this.setUserAccess}>
                  {this._getUserAccessStatus()}
                </select> :

                <ItemLabel text={this._getUserAccessNorwegianNames(this._getUserAccessString())} />}

              <div style={{ marginLeft: "auto" }}>
                <RowLayout>

                  {this.state.accessValue != this.state.origAccValue ? <CheckButton
                    _commitToDatabase={this.updateUserAccess}
                    width="20px"
                    height="20px"
                    margin="0 5px 0 10px" /> : ""}

                  <EditButton
                    onClick={this.editAccess}
                    pushRight={true}
                    width="20px"
                    height="20px"
                    margin="0 0 0 20px"
                  />
                </RowLayout>
              </div>
            </RowLayout>
          </RowLayout>
          <KeyValueFieldEditor
            keyName="RegDato"
            value={<Moment format="DD/MM/YYYY">{creationDate}</Moment>}
            canEdit={false}
            booleanValue={false}
            theId={id}
            parentEditorName="userEditor"
          />

        </ColumnLayout >}
      </div>

    );
  }
}
