export const GET_ERRORS = "GET_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const GET_PROFILES = "GET_PROFILES";

export const GET_APPENDING_USERS = "GET_APPENDING_USERS";
export const APPENDING_USERS_HANDLE_RESPONSE = "APPENDING_USERS_HANDLE_RESPONSE";

export const LOAD_TEMPLATES = "LOAD_TEMPLATES";

export const SET_CART_USER = "SET_CART_USER";
export const SET_CART_ITEMS = "SET_CART_ITEMS";
export const REMOVE_CART_USER = "REMOVE_CART_USER";
export const CLEAR_CART = "CLEAR_CART";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
