import React from "react";
import GlobalDataController from "../../utils/GlobalDataController";
import { searchUsername, searchProduct, searchProductByCategory } from '../../utils/apiFunctions';

export class SearchInputField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
      prevSearchString: ""
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.setSearchStringInGDC();
    });
  };

  handleKeyDown = async (e) => {
    if (this.props.onEnter) {
      if (e.key === "Enter") {
        if (this.props.searchType === "user") {
          var result = await searchUsername(GlobalDataController.userSearchString);
          GlobalDataController.usersBySeach = await result;
        } else if (this.props.searchType === "product") {
          var result = await searchProduct(GlobalDataController.productSearchString);
          GlobalDataController.productsBySearch = await result;
        }
        this.props.onEnter()
      }
    }
  }

  /**
   * This functions is a scalable function.
   * Its function is to set the global variables
   * containing different search strings.
   *
   * If a component is passed property searchType='user', then userSearchString in
   * GlobalDataController (GDC) will be updated.
   *
   * This function can be extended with other search string properties
   * as long as the associated global variable isalso added in in GDC.
   */
  setSearchStringInGDC = () => {
    const { searchType } = this.props;
    switch (searchType) {
      case "user":
        GlobalDataController.userSearchString = this.state.searchString;
        break;
      case "product":
        GlobalDataController.productSearchString = this.state.searchString;
        break;
    }
  };

  render() {
    return (<input style={{
      backgroundColor: "rgba(0,0,0,0.0)",
      padding: "12px 20px",
      margin: "8px 0",
      display: "inline-block",
      outline: "none",
      width: this.props.width ? this.props.width : ""
    }} onKeyDown={this.handleKeyDown} placeholder={this.props.placeholder} name="searchString" id="srch-term-user" type="text" onChange={this.onChange} value={this.state.searchString} />);
  }
}

SearchInputField.propTypes = {
  searchType: (props, propName, componentName) => {
    if (!props.searchTypes) {
      return new Error(`Property searchType is required to use the component '${componentName}'`);
    }
  }
};
