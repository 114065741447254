import { GET_APPENDING_USERS, APPENDING_USERS_HANDLE_RESPONSE } from "../actions/types";

const initialState = {
  users: null,
  onHandleResponse: {} // The response from server when appending user has either been denied or approved
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_APPENDING_USERS:
      return {
        ...state,
        users: Object.assign([], action.payload)
      };
    case APPENDING_USERS_HANDLE_RESPONSE:
      return {
        ...state,
        onHandleResponse: Object.assign({}, action.payload)
      }
    default:
      return state;
  }
}
