// Imports needed for each function
import axios from "axios";
import swal from "sweetalert";
import GlobalDataController from "./GlobalDataController";

//  --------- Functions ----------

/**
 * This function send an email to a user to request a redelivery of products.
 * @param {userId} userId 
 */
export async function redeliveryRequestByEmail(userId) {
  try {
    let result = await axios.post('api/products/productRedeliveryRequest', { userId: userId });
    let data = await result.data;
    return await data;
  } catch (error) {
    console.log(error)
  }
}

/**
 * This function gets all categories and stores them in 
 * the global variable 'templates' present in GlobalDataController.
 */
export async function getAllCategories() {
  try {
    const result = await axios.get('/api/product-category-template/get-temp-names')
    let categories = await result.data.map(temp => temp.category);
    GlobalDataController.categories = categories;
    return await categories;
  } catch (error) {
    console.log(error);
  }
}

/**
 * Search database for products under a specific category
 * @param {string} category 
 */
export async function searchProductByCategory(category) {
  try {
    const result = await axios.post("/api/search/category", { category: category });
    return await result.data;
  } catch (error) {
    console.log(error)
  }
}

/**
 * Search database for users by their username
 * @param {string} username 
 */
export async function searchUsername(username) {
  try {
    const result = await axios.post("/api/search/username", { username: username });
    return await result.data;
  } catch (error) {
    console.log(error)
  }
}

/**
 * Search database for product by product name
 * @param {string} productname
 */
export async function searchProduct(productname) {
  try {
    const result = await axios.post("/api/search/productname", { productname: productname })
    return await result.data;
  } catch (error) {
    console.log(error)
  }
}

/**
 * Retrieves all active borrowers from the database.
 */
export async function getActiveBorrowers() {
  try {
    const activeBorrowers = await axios.get('/api/borrows/activeBorrowers');
    return activeBorrowers.data;
  } catch (error) {
    console.log(error);
  }
}

/**
 * This function saves a new user to the database and then shows a success message.
 * If something went wrong, the error is being console logged.
 *
 * The routePath is an argument because it is also possible to request a
 * registration, which uses another routePath.
 *
 * newUserPath: "api/users/register"
 * requestPath: "api/users/request-registration"
 *
 * newUser datafield must be: name, password, email, empNum, phone,
 * password2, generalLoaner, sysAdm, sysDev and borrower
 *
 * @param {Server route path} routePath, {Object containing the user data} newUser
 */
export function saveNewUserToDatabase(routePath, newUser) {
  return axios
    .post(routePath, newUser)
    .then(() =>
      swal({
        title: "Bruker er lagt til!",
        icon: "success",
        button: "OK"
      })
    )
    .catch(err => {
      return err.response.data
    });
}

/**
 * This functions returns a Promise resulting in the requested user
 * Usage Example: getUserById(theId).then(res => { var user = res.data; console.log(user.name) })
 * @param {String} userId
 */
export function getUserById(userId) {
  return axios.get("/api/users/userById/" + userId);
}

/**
 * This function should be used when a product is being redelivered.
 * The function returns a Promise with the server response after delivery.
 * Usage Example: redeliverBorrowedProduct(postableObject).then(res => {}).catch(err => {})
 * @param {{userId: String, prodId: String, countToRemove: Number}} postableObject
 */
export function redeliverBorrowedProduct(postableObject) {
  return axios.post("api/borrows/redelivery", postableObject).catch(err => {
    swal({
      title: "Det er ikke nok enheter av dette produkter",
      icon: "error",
      button: "OK"
    })
  })
}

/**
 * This function is used to redeliver a list of products. 
 * @param {{userId: string, products: [{id: string, count: number}]}} postableObject 
 */
export async function redeliverListOfProducts(postableObject) {
  try {
    const message = await axios.post('api/borrows/redeliverMany', postableObject);
    return message;
  } catch(error) {
    console.error(error);
  }
}

/**
 * This functions returns a borrowschema from a given user.
 * The function returns a Promise with the server response.
 * Usage Example: getSingleUserBorrowSchema(userId).then(res => {}).catch(err => {})
 * @param {String} userId
 */
export function getSingleUserBorrowSchema(userId) {
  return axios.get("/api/search/borrowschema/" + userId.toString());
}

/**
 * This function saves a new Todo note in the database.
 * The function returns a Promise with the server response.
 * Usage Example: saveNewTodoNoteToDatabase(postableObject).then(res => {}).catch(err => {})
 * @param {{title: String, desc: String, note: String}} postableObject
 */
export function saveNewTodoNoteToDatabase(postableObject) {
  return axios.post("/api/todo/newToDo", postableObject);
}

/**
 * This functions returns all Todo notes from the database.
 * The function returns a Promise with the server response.
 * Usage Example: getAllTodoNotesFromDatabase().then(res => {}).catch(err => {})
 */
export function getAllTodoNotesFromDatabase() {
  return axios.get("/api/todo/getAllTodo");
}

/**
 * This function deletes a Todo note from the database.
 * The function returns a Promise with the server response.
 * Usage Example: deleteTodoNoteFromDatabase(noteId).then(res => {}).catch(err => {})
 * @param {String} noteId
 */
export function deleteTodoNoteFromDatabase(noteId) {
  axios.delete("/api/todo/delete/" + noteId);
}

/**
 * This function registers all items in the shopping cart to the database.
 * This includes adjusting the count on user borrowschema, and the count in the inventory.
 * Usage Example: registerShoppingcartProductsToDatabase(shoppingcartUser, shoppingcartItems).then(res => {}).catch(err => {})
 * The function returns a Promise with the server response.
 * @param {{name: String, id: String}} shoppingcartUser
 * @param {String[]} shoppingcartItems
 */
export function registerShoppingcartProductsToDatabase(
  shoppingcartUser,
  shoppingcartItems
) {
  return axios.post(
    "/api/borrows/productsArray/" + shoppingcartUser.id,
    shoppingcartItems
  );
}

/**
 * This function is used to force delete a product from the database.
 * CAUTION: Should NOT be used for general purposes.
 * This function will delete all products, even the borrowed ones
 * @param {String} productId
 */
export function forceDeleteAProductFromDatabase(productId) {
  return axios.delete("/api/products/force-delete/" + productId);
}

/**
 * This function deletes a product from the database.
 * CAUTION: This function should only be used if the product is not borrowed by anyone.
 * @param {String} productId
 */
export function deleteAProductFromDatabase(productId) {
  return axios.delete("/api/products/delete/" + productId);
}

/**
 * This function returns the related template to the given category.
 * @param {String} category
 */
export function getTemplateByCategory(category) {
  return axios.get("/api/product-category-template/get-template/" + category);
}

/**
 * This function saves a new Template configuration to the database.
 * @param {{category: boolean, title: boolean, value: boolean, productNum: boolean, description: boolean, huntingLicense: boolean, boat: boolean, wetCard: boolean, climbingLicense: boolean, classBE: boolean, img: boolean, count: boolean}} template
 */
export function saveNewTemplateToDatabase(template) {
  return axios.post("/api/product-category-template/new-template", template);
}

/**
 * This functions returns a list of all user who are borrowing the given product.
 * @param {String} productId
 */
export function getAllUsersBorrowingAProduct(productId) {
  return axios.get("/api/products/users-by-borrowed-product/" + productId);
}

/**
 * This function returns a product by the given id
 * @param {String} productId
 */
export function getProductById(productId) {
  return axios.get("/api/products/productById/" + productId);
}

/**
 * This function saves a new product to the database.
 * @param {{productNum: String, title: String, description: String, huntingLicense: boolean, boat: boolean, wetCard: boolean, climbingLicense: boolean, classBE: boolean, category: String, value: Number, count: Number}} newProduct
 */
export function saveNewProductToDatabase(newProduct) {
  return axios.post("/api/products/newProduct", newProduct);
}

/**
 * This function saves a new changelog note to the database.
 * @param {{title: String, desc: String, note: String}} changelogData
 */
export function saveNewChangelogToDatabase(changelogData) {
  return axios.post("/api/changelog/newChange", changelogData);
}

/**
 * This function deletes a changelog with the given id from the database.
 * @param {String} changelogId
 */
export function deleteAChangeLogFromDatabase(changelogId) {
  axios.delete("/api/changelog/delete/" + changelogId);
}

/**
 * This function returns all changelogs from the database
 */
export function getAllChangelogsFromDatabase() {
  return axios.get("/api/changelog/getAllChanges");
}

/**
 * This functions returns all products in the inventory and the count.
 * The server provides the aggregation of all products from inventory and borrowed products.
 */
export function getTotalProductInventoryFromDatabase() {
  return axios.get("/api/system/get-total-inventory");
}

export async function verifyRegisterRequestKey(key) {
  try {
    const res = await axios.get("/api/system/verifyRegisterKey/" + key)
    return res.data
  } catch (e) {
    // pass
  }
}

/**
 * This functions calles the server for a spesific image located in the database
 * @param {String} filename
 */
export function getImageFromDatabase(filename) {
  return axios
    .get("/api/fileHandler/image/" + filename, { responseType: "arraybuffer" })
    .then(res => {
      return new Buffer(res.data, "binary").toString("base64");
    }).catch(err => {
      //
    })
}
