import React, { Component } from 'react';
import CartItem from './CartItem';
import RowLayout from './RowLayout';

class CartContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount() {
        this.setState({
            items: this.props.cartItems
        })
    }

    /**
     * ref is a reference to the original ProductItem and is used to set isInCart: false
     * @param {*} id 
     * @param {*} ref 
     */
    removeItem(id, ref) {
        var filtered = this.state.items.filter(item => item.id !== id)
        ref.setState({
            isInCart: false
        })
        this.setState({
            items: filtered
        }, () => this.props.deleteState(this.props.cartItems, id))
    }

    updateCartState = (id, count) => {
        var { items } = this.state;

        items.forEach(item => {
            if (item.id === id) {
                item.count = count;
            }
        })

        this.setState({
            items: items
        }, () => this.props.updateState(items))
    }

    render() {
        return (
            <div>
                <h3>{this.props.userName}</h3>
                {this.state.items.map(item => (
                    <RowLayout>
                        <CartItem
                            key={item.id}
                            name={item.name}
                            category={item.category}
                            max={item.max}
                            min={item.min}
                            id={item.id}
                            imgSrc={item.img}
                            updateCart={this.updateCartState}
                        />
                        <img
                            onClick={() => this.removeItem(item.id, item.ref)}
                            src={require("../../img/ic_delete_48px.png")}
                            alt={"..."}
                            className="pointer iconButtonActive"
                            style={{
                                height: "50px",
                                width: "50px",
                                marginLeft: "auto",
                                marginBottom: "auto",
                                marginTop: "auto"
                            }}
                        />
                    </RowLayout>
                ))}
            </div>
        );
    }
}

export default CartContainer;