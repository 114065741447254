import React, { Component } from "react";
import { connect } from "react-redux";
import { setCartItems } from "../../actions/shoppingcartActions";
import { getImageFromDatabase } from "../../utils/apiFunctions";
import { PopMsg } from "./PopupMessage";
import ProductEditor from "../product/ProductEditor";
import { ItemImage } from "./ItemImage";
import RowLayout from "./RowLayout";
import ColumnLayout from "./ColumnLayout";
import { AddProductButton } from "./AddProductButton";
import { ItemLabel } from "./ItemLabel";
import { CountStickerLabel } from "./CountStickerLabel";
import Tooltip from "./Tooltip";


/**
 * This class presents a product from the database
 * Author: Øyvind Johannessen
 * Author: Martin Svendsen
 * Version 0.2
 */
class ProductItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      image: "https://dummyimage.com/180x180/fff/000000&text=No+Image",
      isInCart: false
    };
    this._addToCart = this._addToCart.bind(this);
  }

  /**
   * When component mounts set it's state.count to the products count
   * This is dont so that we can control increment and decrement of the count in the component state.
   * It does not affect the actual count in the database.
   * The database count is only affected when the shoppingcart is checked out.
   */
  componentDidMount() {
    if (this.props.imgSrc !== " ") {
      getImageFromDatabase(this.props.imgSrc).then(res => {
        this.setState({
          image: "data:image/png;base64," + res
        });
      });
    }
    this.setState({
      count: this.props.count
    });

    this.isInCart();
  }

  isInCart = () => {
    var shoppingCart = this.props.shoppingCart;

    shoppingCart.items.forEach(item => {
      if (item.id === this.props.id) {
        item.ref = this;
        this.setState({
          isInCart: true
        })
      }
    })
  }

  /**
   * Adds an item to the shoppingcart that is positioned in the navbar
   */
  _addToCart() {
    if (this.state.count === 0) {
      PopMsg({
        message: "Tomt 👎🏻",
        left: "None",
        right: "0",
        bottom: "None",
        duration: 1,
        color: "#FEEFB3"
      });
    } else {
      var shoppingCart = this.props.shoppingCart;

      if (this.state.isInCart) {
        shoppingCart.items = shoppingCart.items.filter(item => item.id !== this.props.id)

        this.setState({
          isInCart: false
        })

        PopMsg({
          message: "Fjernet fra handlekurv 👍🏻",
          left: "None",
          right: "0",
          bottom: "None",
          duration: 1,
          color: "#DFF2BF"
        });

      } else {
        shoppingCart.items.push({
          name: this.props.name,
          category: this.props.category,
          id: this.props.id,
          count: 1,
          max: this.props.count,
          min: 0,
          img: this.props.imgSrc,
          ref: this
        });

        this.setState({
          isInCart: true
        })

        PopMsg({
          message: "Lagt til handlekurv 👍🏻",
          left: "None",
          right: "0",
          bottom: "None",
          duration: 1,
          color: "#DFF2BF"
        });
      }

      this.props.setCartItems(shoppingCart.items);
    }
  }

  shareProductIdWithParent = () => {
    if (this.props.parent) {
      this.props.parent.setState(
        {
          productId: undefined,
          productName: undefined
        },
        () => {
          this.props.parent.setState({
            productId: this.props.id,
            productName: this.props.name
          });
        }
      );
    }
  };

  /**
   * Render this component
   */
  render() {
    const addToCartButton = this.props.includeAddButton ? (
      <Tooltip text={`Legg til ${this.props.name} i handlekurv.`}>
        <AddProductButton
          _addToCart={this._addToCart}
          pushRight={true}
          pushBottom={true}
          pushTop={true}
          padding="0px 10px 0px 10px"
        />
      </Tooltip>
    ) : (
        " "
      );

    return (
      <div
        className={this.props.className ? this.props.className + " bottomPadding" : "bottomPadding"}
        style={{ position: "relative", maxWidth: "400px", minWidth: "400px", margin: "10px", animation: "fadein 1s", opacity: this.state.isInCart ? "0.3" : "1" }}
        label={this.props.id}
        onClick={this.shareProductIdWithParent}
      >
        <RowLayout stretchWidth={true}>
          <ItemImage image={this.state.image} />
          <ColumnLayout stretchWidth={true} spaceLeft={true} pushTop={true} pushBottom={true}>
            <ItemLabel
              text={this.props.category}
              italic={true}
              color="#686868"
            />
            <ItemLabel text={this.props.name} />
          </ColumnLayout>
          {addToCartButton}
        </RowLayout>

        <CountStickerLabel count={this.state.count}></CountStickerLabel>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  shoppingCart: state.shoppingCart
});

export default connect(mapStateToProps, { setCartItems })(ProductItem);
