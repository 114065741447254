import React, { Component } from "react";
import { connect } from "react-redux";
import swal from "sweetalert";
import { loadTemplates } from "../../actions/templateActions";
import { getTemplateByCategory, saveNewProductToDatabase } from "../../utils/apiFunctions";
import "./css/product.css";
import { CategorySelector } from "../common/CategorySelector";
import { InputField } from "../common/InputField";
import { ItemLabel } from "../common/ItemLabel";
import ColumnLayout from "../common/ColumnLayout";
import RowLayout from "../common/RowLayout";

class RegisterNewProduct extends Component {
  constructor() {
    super();
    this.state = {
      productNum: "",
      title: "",
      description: "",

      huntingLicense: false,
      boat: false,
      wetCard: false,
      climbingLicense: false,
      classBE: false,

      category: "",
      value: "",
      count: "1",
      img: "",
      errors: {},

      template: "",
      templateNames: [],

      categoryValue: ""
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  getTemplates = (category) => {
    if (category === "") {
      this.setState({
        categoryValue: category,
        template: ""
      })
    } else {
      this.setState({ categoryValue: category }, () => {
        getTemplateByCategory(this.state.categoryValue).then(res => {
          this.setState({ template: res.data });
        });
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    // Her bruker nå FormData for å kunne sende med filer (produkt bilde fil).
    // Multer håndterer filen på server siden.
    // BodyParser håndterer resten av dataene.
    var newProduct = new FormData();

    newProduct.set("productNum", this.state.productNum);
    newProduct.set("title", this.state.title);
    newProduct.set("description", this.state.description);
    newProduct.set("huntingLicense", this.state.huntingLicense);
    newProduct.set("boat", this.state.boat);
    newProduct.set("wetCard", this.state.wetCard);
    newProduct.set("climbingLicense", this.state.climbingLicense);
    newProduct.set("classBE", this.state.classBE);
    newProduct.set("category", this.state.categoryValue);
    newProduct.set("value", this.state.value);
    newProduct.set("count", this.state.count);
    if (this.state.img !== "") {
      newProduct.append("productImage", this.state.img, this.state.img.name);
    }
    console.log(newProduct);

    saveNewProductToDatabase(newProduct)
      .then(() =>
        swal({
          title: "Produkt er lagt til!",
          icon: "success",
          button: "OK"
        })
      )
      .catch(err => console.log(err.response.data));
  }

  componentDidMount() {
    this.props.loadTemplates();
  }

  static getDerivedStateFromProps(props, state) {
    let tempNames = props.templates.map(temp => temp.category);
    return { templateNames: tempNames };
  }

  render() {
    const { template } = this.state;
    const { templateNames } = this.state;
    let productTemplate;

    if (template) {
      productTemplate = (
        <form onSubmit={this.onSubmit} style={{width: "100%"}}>
          <ColumnLayout stretch={true} spaceTop={true}>
            <ItemLabel text="Produkt Mal" bold={true} />
            {template.title ? (
              <InputField
                type="text"
                name="title"
                placeholder="Tittel"
                onChange={e => this.setState({ title: e.target.value })}
              />

            ) : (
                ""
              )}
            {template.productNum ? (
              <InputField
                type="text"
                name="productNum"
                placeholder="Produkt Nummer"
                onChange={e => this.setState({ productNum: e.target.value })}
              />
            ) : (
                ""
              )}
            {template.value ? (
              <InputField
                type="number"
                name="value"
                placeholder="Verdi"
                onChange={e => this.setState({ value: e.target.value })}
              />
            ) : (
                ""
              )}
            {template.count ? (
              <InputField
                type="number"
                name="count"
                placeholder="Antall"
                onChange={e => this.setState({ count: e.target.value })}
              />
            ) : (
                ""
              )}
            <ColumnLayout spaceTop={true}>
              {template["userDemands"].huntingLicense ? (
                <RowLayout>
                  <InputField
                    type="checkbox"
                    name="huntingLicense"
                    onChange={e => this.setState({ huntingLicense: e.target.checked })}
                  />
                  <ItemLabel text="Jeger sertifikat" bold={true} pushRight={true} />
                </RowLayout>
              ) : (
                  ""
                )}
              {template["userDemands"].boat ? (
                <RowLayout>
                  <InputField
                    type="checkbox"
                    name="boat"
                    onChange={e => this.setState({ boat: e.target.checked })}
                  />
                  <ItemLabel text="Båt sertifikat" bold={true} pushRight={true} />
                </RowLayout>
              ) : (
                  ""
                )}
              {template["userDemands"].wetCard ? (
                <RowLayout>
                  <InputField
                    type="checkbox"
                    name="wetCard"
                    onChange={e => this.setState({ wetCard: e.target.checked })}
                  />
                  <ItemLabel text="Våt sertifikat" bold={true} pushRight={true} />
                </RowLayout>
              ) : (
                  ""
                )}
              {template["userDemands"].climbingLicense ? (
                <RowLayout>
                  <InputField
                    type="checkbox"
                    name="climbingLicense"
                    onChange={e => this.setState({ climbingLicense: e.target.checked })}
                  />
                  <ItemLabel text="Brattkort" bold={true} pushRight={true} />
                </RowLayout>
              ) : (
                  ""
                )}
              {template["userDemands"].classBE ? (
                <RowLayout>
                  <InputField
                    type="checkbox"
                    name="classBE"
                    onChange={e => this.setState({ classBE: e.target.checked })}
                  />
                  <ItemLabel text="Klasse BE" bold={true} pushRight={true} />
                </RowLayout>
              ) : (
                  ""
                )}

            </ColumnLayout>

            {template.description ? (
              <InputField
                name="description"
                placeholder="Beskrivelse"
                onChange={e => this.setState({ description: e.target.value })}
              />
            ) : (
                ""
              )}
            {template.img ? (
              <InputField
                type="file"
                name="img"
                placeholder="Bilde"
                onChange={e => this.setState({ img: e.target.files[0] })}
              />
            ) : (
                ""
              )}
            <div className="btn-product-container">
              <button
                type="reset"
                className="btn-registration btn-cancel-registration"
                onClick={this.showHide}
              >
                Reset
            </button>
              <button
                type="submit"
                className="btn-registration btn-confirm-registration"
              >
                Lagre produkt
            </button>
            </div>
          </ColumnLayout>
        </form>
      );
    }

    return (
      <ColumnLayout stretchWidth={true}>
        <CategorySelector useCategory={this.getTemplates} />
        {productTemplate}
      </ColumnLayout>
    );
  }
}

const mapStateToProps = state => ({
  templates: state.template
});

export default connect(
  mapStateToProps,
  { loadTemplates }
)(RegisterNewProduct);
