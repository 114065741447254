import React from "react";
import ColumnLayout from "./ColumnLayout";

const errorStyle = {
    fontSize: "16px",
    color: "red"
}

export const InputField = (props) => (
    <ColumnLayout>
        <input
            className={props.className}
            placeholder={props.placeholder}
            type={props.type}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            minLength={props.minLength}
            maxLength={props.maxLength}
            min="1"
            style={{
                marginLeft: props.pushRight ? "auto" : props.marginLeft ? props.marginLeft : "",
                marginRight: props.pushLeft ? "auto" : props.marginRight ? props.marginRight : "",
                marginTop: props.pushBottom ? "auto" : props.marginTop ? props.marginTop : "8px",
                marginBottom: props.pushTop ? "auto" : props.marginBottom ? props.marginBottom : "8px",
                backgroundColor: "rgba(0,0,0,0.0)",
                padding: props.padding ? props.padding : "12px 20px",
                display: "inline-block",
                outline: "none",
                width: props.width ? props.width : "",
                fontSize: props.fontSize,
            }}
        />
        {renderErrorMessage(props)}
    </ColumnLayout>
);

function renderErrorMessage(props) {
    return props.errorMessage ? <label style={errorStyle}>{props.errorMessage}</label> : "";
}

