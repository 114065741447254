import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import axios from "axios";
import swal from "sweetalert";
import { isBorrower } from "../../utils/conditionalRenderFunctions";

// CSS
import "./css/login-style.css";
import { InputField } from "../common/InputField";
import { ItemLabel } from "../common/ItemLabel";
import ColumnLayout from "../common/ColumnLayout";
import RowLayout from "../common/RowLayout";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (!isBorrower()) {
        this.props.history.push("/dashbord");
      } else {
        this.props.history.push("/produkt");
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      if (!isBorrower()) {
        this.props.history.push("/dashbord");
      } else {
        this.props.history.push("/produkt");
      }
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  resetPassword() {
    swal({
      title: "Skriv inn epost",
      content: {
        element: "input",
        attributes: {
          placeholder: "Epost"
        }
      }
    }).then(result => {
      if (result) {
        axios.post("/forgot", { email: result }).then(() => {
          swal({
            title:
              "Du skal nå ha mottatt en mail med instruksjoner om hva som må gjøres for å opprette et nytt passord. Hvis eposten ikke er i innboksen, må du sjekke søppel-innboksen.",
            icon: "success",
            button: false,
            text: "  "
          });
        });
      }
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginUser(userData);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="loginWrapper">
        <img
          id="loginLogo"
          alt="forsvarsets logo"
          src={require("../../img/Forsvarets_merke.png")}
        />
        <ColumnLayout
          width="75%"
          pushRight={true}
          pushLeft={true}
          pushTop={true}
          pushBottom={true}
        >
          <form id="login-form" onSubmit={this.onSubmit}>
            <ColumnLayout>
              <ItemLabel text="Epost" color="#dddddd" paddingBottom="10px" />
              <InputField
                type="email"
                placeholder="Skriv inn epost..."
                name="email"
                onChange={this.onChange}
                value={this.state.email}
                errorMessage={errors.email}
                className="WhiteBorder"
              />
              <ItemLabel
                text="Passord"
                color="#dddddd"
                paddingTop="20px"
                paddingBottom="10px"
                margin="0"
              />
              <InputField
                type="password"
                placeholder="Skriv inn passordet ditt her..."
                name="password"
                onChange={this.onChange}
                value={this.state.password}
                errorMessage={errors.password}
                className="WhiteBorder"
              />
              <input type="submit" className="loginBtn" value="Logg inn" />
            </ColumnLayout>
          </form>
          <RowLayout stretchWidth={true}>
            <button className="forgotPw btn">
              <span onClick={e => this.resetPassword(e)}>Glemt passord?</span>
            </button>
            <button className="forgotPw btn">
              <Link to="/request-registration">
                <span>Registrer deg</span>
              </Link>
            </button>
          </RowLayout>
        </ColumnLayout>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginUser })(Login);
