import axios from "axios";

import {
  GET_APPENDING_USERS,
  GET_ERRORS,
  APPENDING_USERS_HANDLE_RESPONSE
} from "./types";
import { EWOULDBLOCK } from "constants";

/**
 * Get all appending approval users
 */
export const getAppendingUsers = () => dispatch => {
  axios
    .get("/api/users/get-appending-users")
    .then(res => {
      dispatch({
        type: GET_APPENDING_USERS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      });
    });
};

/**
 * Approve user registration request
 */
export const approveAppendingUser = userId => dispatch => {
  return axios
    .post("/api/users/approve-appending-user", userId)
    .then(res => {
      dispatch({
        type: APPENDING_USERS_HANDLE_RESPONSE,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: APPENDING_USERS_HANDLE_RESPONSE,
        payload: err.response.data
      });
    });
};

/**
 * Deny user registration request
 */
export const denyAppendingUser = userId => dispatch => {
  return axios
    .post("/api/users/deny-appending-user", userId)
    .then(res => {
      dispatch({
        type: APPENDING_USERS_HANDLE_RESPONSE,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: APPENDING_USERS_HANDLE_RESPONSE,
        payload: err.response.data
      });
    });
};
