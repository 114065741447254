import React, { Component } from "react";
import jwt_decode from "jwt-decode";
import "./css/profile-style.css";
import UserEditor from ".././/borrower/UserEditor";
import ModalWindow from "../common/ModalWindow";

var token;
var decoded;
var userImgString;

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      userImg: "",
      access: "",
      id: "",
      showModal: false
    };
  }

  componentDidMount() {
    token = localStorage.getItem("jwtToken", token);
    decoded = jwt_decode(token);
    userImgString = decoded.avatar;
    this.setState({
      userName: decoded.name,
      id: decoded.id,
      userImg: userImgString,
      access: decoded.userAccess
    });
  }

  render() {
    console.log(decoded);
    return (
      <div
        className="profile-div"
        onClick={() => this.setState({ showModal: true })}
      >
        <img
          alt="Dummy"
          className="profile-pictures"
          id="profile-picture"
          src={
            userImgString === undefined
              ? "https://dummyimage.com/180x180/ddd/000000&text=No+Image"
              : decoded.avatar
          }
        />
        <div>{this.state.userName}</div>
        {!this.state.showModal ? "" : <UserEditor id={this.state.id} />}
      </div>
    );
  }
}
export default Profile;
