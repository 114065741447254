import React, { Component } from "react";
import { connect } from "react-redux";
import ProductItem from "../common/ProductItem";
import UserItem from "../common/UserItem";
import GlobalDataController from "../../utils/GlobalDataController";
import ColumnLayout from "../common/ColumnLayout";
import { SearchInputField } from "../common/SearchInputField";
import { SearchButton } from "../common/SearchButton";
import { CategorySelector } from "../common/CategorySelector";
import RowLayout from "../common/RowLayout";
import ContentContainer from "../common/ContentContainer";
import { ItemLabel } from "../common/ItemLabel";
import { searchProduct } from "../../utils/apiFunctions";

class Loan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  setIsLoading = () => {
    const { isLoading } = this.state;
    this.setState({
      isLoading: isLoading ? false : true
    });
  }

  componentDidMount() {
    GlobalDataController.productsBySearch = [];
  }

  render() {
    return (
      <ContentContainer>
        <RowLayout stretchWidth={true}>
          <ColumnLayout stretchWidth={true} spaceRight={true} className="align-center">
            <ItemLabel text="Søk" bold={true} marginBottom="10px" />
            <SearchInputField
              searchType="user"
              placeholder="Søk etter person..."
              onEnter={this.setIsLoading}
            />
            <SearchInputField
              searchType="product"
              placeholder="Søk etter produkt..."
              onEnter={this.setIsLoading}
            />
            <CategorySelector />
            <SearchButton
              callback={this.setIsLoading}
              searchType="all"
              text="Søk"
            />
          </ColumnLayout>

          <ColumnLayout
            stretchWidth={true}
            stretchHeight={true}
            spaceRight={true}
            spaceLeft={true}
            borderLeft={true}
            className="align-center"
          >
            <ItemLabel text={"Låntaker"} bold={true} marginBottom="10px" />
            <ColumnLayout scrollable={true}>
              {GlobalDataController.usersBySeach.map(user => {
                return (
                  <UserItem
                    growValue="1"
                    key={user._id}
                    name={user.name}
                    empNum={user.employeeNumber}
                    imgSrc={user.avatar}
                    id={user._id}
                    includeAddButton={true}
                    onClickView="borrowSchema"
                  />
                );
              })}
            </ColumnLayout>
          </ColumnLayout>

          <ColumnLayout
            stretchWidth={true}
            stretchHeight={true}
            spaceLeft={true}
            borderLeft={true}
            className="align-center"
          >
            <ItemLabel text={"Produkt"} bold={true} marginBottom="10px" />
            <ColumnLayout scrollable={true}>
              {GlobalDataController.productsBySearch.map(product => {
                return (
                  <ProductItem
                    growValue="1"
                    key={product._id}
                    name={product.title}
                    category={product.category}
                    count={product.count}
                    id={product._id}
                    imgSrc={product.img}
                    includeAddButton={true}
                    onClickView={undefined}
                  />
                );
              })}
            </ColumnLayout>
          </ColumnLayout>
        </RowLayout>
      </ContentContainer>
    );
  }
}

/**
 * Maps redux state (productsBySearch, categoryBySearch) to this components equivalent props
 * @param {Redux state} state
 */
const mapStateToProps = state => ({
  productsBySearch: state.productsBySearch,
  cartUser: state.shoppingCart.user
});

export default connect(mapStateToProps)(Loan);
