import React, { Component } from "react";
import ChangelogList from "./ChangelogList";
import { saveNewChangelogToDatabase } from "../../utils/apiFunctions";
import Swal from "sweetalert2";
import "./css/changelog-style.css";
class Changelog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markdownChangelog: "",
      changeLogList: []
    };
    this.child = React.createRef();
    this.newChangelogModal = this.newChangelogModal.bind(this);
  }

  newChangelogModal() {
    Swal({
      title: "Ny endring",
      html:
        "<span>Fyll inn feltene under</span>" +
        '<input id="swal-input1" class="swal2-input" placeholder="Tittel">' +
        '<input id="swal-input2" class="swal2-input" placeholder="Beskrivelse">' +
        '<input id="swal-input3" class="swal2-input" placeholder="Notat">',
      focusConfirm: false,
      showCancelButton: true,
      cancelButtonText: "Avbryt",
      preConfirm: () => {
        return [
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value,
          document.getElementById("swal-input3").value
        ];
      }
    }).then(preConfirm => {
      if (!preConfirm.dismiss) {
        const changelogData = {
          title: preConfirm.value[0],
          desc: preConfirm.value[1],
          note: preConfirm.value[2]
        };
        try {
          saveNewChangelogToDatabase(changelogData).then(() => {
            this.child.current.componentDidMount();
          });
        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  showHide() {
    document.getElementById("changelog-container").classList.toggle("show");
  }
  render() {
    return (
      <div className="changelog">
        <button onClick={this.showHide} className="showHideChangelog-btn">
          Vis endringer
        </button>
        <div className="changelog-container" id="changelog-container">
          <div className="changelog-barrier">
            <button className="web add-btn fa" onClick={this.newChangelogModal}>
              <i className="fas fa-plus-circle" />
            </button>
            <ChangelogList ref={this.child} />
          </div>
        </div>
      </div>
    );
  }
}
export default Changelog;
