import React, { Component } from "react";
import { getAllChangelogsFromDatabase } from "../../utils/apiFunctions";
import ChangelogItem from "./ChangelogItem";
class TodoList extends Component {
  constructor() {
    super();
    this.state = {
      changelogList: []
    };

    this._removeChangelogItem = this._removeChangelogItem.bind(this);
  }

  _removeChangelogItem(id) {
    var filteredChangelog = this.state.changelogList.filter(result => {
      var resultString = "";
      if (result.props.id.toString() !== id) {
        resultString = result;
      }
      return resultString;
    });
    this.setState({
      changelogList: filteredChangelog
    });
  }
  componentDidMount() {
    var list = [];
    getAllChangelogsFromDatabase().then(result => {
      var key = 1;
      result.data.forEach(element => {
        list.push(
          <ChangelogItem
            id={element._id}
            key={key}
            header={element.title}
            date={element.creationDate}
            desc={element.desc}
            note={element.note}
            removeMe={this._removeChangelogItem}
          />
        );
        key++;
      });
      this.setState({
        changelogList: list.reverse()
      });
    });
  }

  render() {
    return (
      <div>
        <div className="col-sm">
          <div className="list-group">
            {this.state.changelogList}
            <div>{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default TodoList;
