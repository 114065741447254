import React from "react";

export const ItemLabel = props => (
  <label
    className={props.className ? props.className : ""}
    style={{
      //marginLeft: props.pushRight ? props.pushRight : "auto",
      //marginRight: props.pushLeft ? props.pushLeft : "auto",
      position: props.position,
      top: props.top,
      right: props.right,
      bottom: props.bottom,
      left: props.left,
      marginLeft: props.marginLeft ? props.marginLeft : "",
      marginRight: props.marginRight ? props.marginRight : "",
      margin: props.margin ? props.margin : "",
      marginTop: props.marginTop ? props.marginTop : "",
      marginBottom: props.marginBottom ? props.marginBottom : "",
      wordBreak: props.wordBreak ? props.wordBreak : "break-word",
      maxWidth: "450px",
      fontWeight: props.bold ? "bold" : "normal",
      fontStyle: props.italic ? "italic" : "normal",
      color: props.color,
      paddingLeft: props.paddingLeft,
      paddingRight: props.paddingRight,
      paddingTop: props.paddingTop,
      paddingBottom: props.paddingBottom ? props.paddingBottom : "",
      fontSize: props.fontSize ? props.fontSize : "0.9em",
      width: props.width ? props.width : "",
      maxWidth: props.maxWidth ? props.maxWidth : ""
    }}
  >
      {props.text}

  </label>
);
