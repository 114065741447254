import React, { Component } from "react";
import TodoItem from "./TodoItem";
import axios from "axios";
import { getAllTodoNotesFromDatabase } from "../../../utils/apiFunctions";
import "../css/dashboard-style.css";
import ColumnLayout from "../../common/ColumnLayout";
import RowLayout from "../../common/RowLayout";
class TodoList extends Component {
  constructor() {
    super();
    this.state = {
      todoItemList: []
    };

    this.removeTodoItem = this.removeTodoItem.bind(this);
  }

  removeTodoItem(id) {
    var filter = this.state.todoItemList.filter(result => {
      var res = "";
      if (result.props.id.toString() !== id) {
        res = result;
      }
      return res;
    });

    this.setState({
      todoItemList: filter
    });
  }

  componentDidMount() {
    var list = [];
    getAllTodoNotesFromDatabase().then(result => {
      var key = 1;
      result.data.forEach(element => {
        var date = new Date(element.creationDate).toDateString();
        list.push(
          <TodoItem
            id={element._id}
            key={key}
            header={element.title}
            s
            date={date}
            desc={element.desc}
            note={element.note}
            removeMe={this.removeTodoItem}
          />
        );
        key++;
      });
      this.setState({
        todoItemList: list
      });
    });
  }

  render() {
    return (
      <ColumnLayout stretchWidth={true} scrollable={true}>
        <RowLayout spaceBottom={true}>
          <div>{this.props.children}</div>
        </RowLayout>
        {this.state.todoItemList.reverse()}
      </ColumnLayout>
    );
  }
}

export default TodoList;
