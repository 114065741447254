import React, { Component } from "react";
import RegisterNewProfile from "./RegisterNewProfile";
import { SearchInputField } from "../common/SearchInputField";
import { SearchButton } from "../common/SearchButton";
import GlobalDataController from "../../utils/GlobalDataController";
import ColumnLayout from "../common/ColumnLayout";
import { ItemLabel } from "../common/ItemLabel";
import UserItem from "../common/UserItem";
import RowLayout from "../common/RowLayout";
import ContentContainer from "../common/ContentContainer";
import BorrowItem from "../common/BorrowItem";
import UserEditor from "./UserEditor";
import {
  redeliverBorrowedProduct,
  getSingleUserBorrowSchema
} from "../../utils/apiFunctions";
import ProductItem from "../common/ProductItem";
import BorrowSchemaEditor from "../common/BorrowSchemaEditor";
import Tooltip from "../common/Tooltip";

class BorrowerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      userSchema: undefined,
      userName: "Låntakers",
      userId: undefined,
      userItem: undefined
    }
  }

  setIsLoading() {
    const { isLoading } = this.state;
    this.setState({
      isLoading: isLoading ? false : true
    })
  }

  updateUserSchema = () => {
    getSingleUserBorrowSchema(this.state.userId).then(res => {
      const schema = res.data.borrows;
      this.setState({
        userSchema: schema
      });

      this.state.userItem.setState({
        schema: schema
      })
    }).catch(console.log);
  }

  render() {
    return (
      <ContentContainer>
        <RowLayout stretchWidth={true} stretchHeight={true}>

          <ColumnLayout stretchWidth={true} spaceRight={true} className="align-center">
            <ItemLabel text="Ny Låntaker" bold={true} marginBottom="10px" />
            <RegisterNewProfile
              userAccessView={true}
              routeName="api/users/register"
              className="align-center"
            />
          </ColumnLayout>

          <ColumnLayout stretchWidth={true} stretchHeight={true} borderLeft={true} spaceRight={true} spaceLeft={true} className="align-center">
            <ItemLabel text={"Låntaker"} bold={true} marginBottom="10px" />
            <ColumnLayout stretchWidth={true}>
              <RowLayout stretchWidth={true}>
                <SearchInputField width="100%" searchType="user" placeholder="Søk etter person..." onEnter={() => {
                  this.setIsLoading();
                }} />
                <SearchButton callback={() => { this.setIsLoading() }} searchType="user" text="Søk" />
              </RowLayout>
            </ColumnLayout>

            <ColumnLayout spaceRight={true} spaceLeft={true} scrollable={true} >
              {GlobalDataController.usersBySeach.map(user => (
                <Tooltip text={`${user.name}: Trykk på bilde for å vise låneskjema eller blyant for å redigere bruker.`}>
                  <UserItem
                    growValue="1"
                    key={user._id}
                    name={user.name}
                    empNum={user.employeeNumber}
                    imgSrc={user.avatar}
                    id={user._id}
                    includeAddButton={false}
                    includeEditButton={true}
                    parent={this}
                    className="ItemHover pointer"
                  />
                </Tooltip>
              ))}
            </ColumnLayout>
          </ColumnLayout>

          <ColumnLayout stretchWidth={true} stretchHeight={true} borderLeft={true} spaceRight={true} spaceLeft={true} className="align-center">
            <ColumnLayout>
              <ItemLabel text={this.state.userName + " Låneskjema"} bold={true} marginBottom="10px" />
            </ColumnLayout>

            <ColumnLayout scrollable={true} paddingTop="49px">
              <BorrowSchemaEditor
                items={this.state.userSchema}
                userId={this.state.userId}
                parent={this}
              />
            </ColumnLayout>

          </ColumnLayout>

        </RowLayout>
      </ContentContainer>
    );
  }
}

export default BorrowerContainer;
