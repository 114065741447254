import React, { Component } from "react";
import { Moment } from "react-moment";
import {
  getImageFromDatabase,
  redeliverBorrowedProduct
} from "../../utils/apiFunctions";
import { ItemImage } from "./ItemImage";
import ColumnLayout from "./ColumnLayout";
import { ItemLabel } from "./ItemLabel";
import RowLayout from "./RowLayout";
import { CountStickerLabel } from "./CountStickerLabel";
import Tooltip from "./Tooltip";
class BorrowItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "https://dummyimage.com/180x180/fff/000000&text=No+Image",
      redeliveryVisable: false,
      countToRemove: 0
    };
  }

  /**
   * When component mounts set it's state.count to the products count
   * This is dont so that we can control increment and decrement of the count in the component state.
   * It does not affect the actual count in the database.
   * The database count is only affected when the shoppingcart is checked out.
   */
  componentDidMount() {
    if (this.props.imgSrc !== " ") {
      getImageFromDatabase(this.props.imgSrc).then(res => {
        this.setState({
          image: "data:image/png;base64," + res
        });
      });
    }
  }

  maxCount = count => {
    return count < this.props.count;
  };

  minCount = count => {
    return count > 0;
  };

  incrementReturnCount = () => {
    var count = this.state.countToRemove;
    if (this.maxCount(count)) {
      count = count + 1;
    }

    if (this.props.parent) {
      this.setState(
        {
          countToRemove: count
        },
        this.props.parent.setState({
          countToRemove: count
        })
      );
    }
  };

  decrementReturnCount = () => {
    var count = this.state.countToRemove;
    if (this.minCount(count)) {
      count = count - 1;
    }

    if (this.props.parent) {
      this.setState(
        {
          countToRemove: count
        },
        this.props.parent.setState({
          countToRemove: count
        })
      );
    }
  };

  setInParentState = () => {
    if (this.props.parent) {
      if (!this.state.redeliveryVisable) {
        this.props.parent.setState({
          selectedItem: this.props.id,
          countToRemove: 0
        });
      } else {
        this.props.parent.setState({
          selectedItem: undefined,
          countToRemove: undefined
        });
      }
    }

    this.setState({
      redeliveryVisable: !this.state.redeliveryVisable ? true : false
    });
  };

  render() {
    const dateOptions = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };

    // Properties
    var loanEnd = this.props.loanEnd;
    var loanStart = this.props.loanStart;
    var dateLoanEnd = new Date(loanEnd);
    var dateLoanStart = new Date(loanStart);
    var dateLoanStart = dateLoanStart.toLocaleDateString("no-NB", dateOptions);
    var dateLoanEnd = dateLoanEnd.toLocaleDateString("no-NB", dateOptions);

    return (
      <div className="ItemHover pointer bottomPadding swipeInRightAnimation">
        <div
          style={{
            maxWidth: "400px",
            minWidth: "400px",
            position: "relative",
            margin: "10px",
            animation: "fadein 0.5s"
          }}
          label={this.props.id}
          onClick={this.setInParentState}
        >
          <RowLayout>
            <ItemImage image={this.state.image} />
            <ColumnLayout stretchWidth={true} spaceLeft={true}>
              <ItemLabel
                text={this.props.category}
                color="#686868"
                fontSize="0.8em"
              />
              <ItemLabel text={this.props.name} fontSize="0.9em" />
              <ItemLabel fontSize="12px" text={"Fra: " + dateLoanStart} />
              <ItemLabel fontSize="12px" text={"Til: " + dateLoanEnd} />
            </ColumnLayout>
          </RowLayout>
          <CountStickerLabel count={this.props.count} />
        </div>
        <div style={{ maxWidth: "500px", margin: "10px" }}>
          {this.state.redeliveryVisable ? (
            <ColumnLayout borderBottom={true} stretchWidth={true}>
              <Tooltip text="Velg antall som skal leveres tilbake.">
                <RowLayout stretchWidth={true} spaceBottom="5px" className="swipeInRightAnimation">
                  <ItemLabel text="Tilbakelevering:" />
                  <i
                    onClick={this.decrementReturnCount}
                    style={{ marginLeft: "auto" }}
                    className="fa fa-minus"
                    aria-hidden="true"
                  ></i>
                  <ItemLabel
                    text={this.state.countToRemove}
                    paddingRight="10px"
                  />
                  <i
                    onClick={this.incrementReturnCount}
                    className="fa fa-plus"
                    aria-hidden="true"
                  ></i>
                </RowLayout>
              </Tooltip>
              {this.state.countToRemove !== 0 ? (
                <button className="swipeInRightAnimation" onClick={this.props.returnProduct}>
                  Lever Tilbake
                </button>
              ) : (
                  ""
                )}
            </ColumnLayout>
          ) : (
              ""
            )}
        </div>
      </div>
    );
  }
}

export default BorrowItem;
