import React from "react";
export class CheckButton extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <img
        src={require("../../img/ic_check_circle_48px.png")}
        onClick={this.props._commitToDatabase}
        style={{
          width: this.props.width ? this.props.width : "40px",
          height: this.props.height ? this.props.height : "40px",
          margin: this.props.margin,
          padding: this.props.padding
        }}
        className="pointer iconButtonActive" />
    );
  }
}
