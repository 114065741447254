import React from "react";
import { searchUsername, searchProduct, searchProductByCategory } from '../../utils/apiFunctions';
import GlobalDataController from "../../utils/GlobalDataController";

/**
 * SearchButton Component
 * A button made for searching the databse based on strings.
 * Dependencies: GlobalDataController
 * Props:
 *    callback -> A callback function. Mostly used to update parent view when a search is done.
 *    searchType -> 'user' OR 'product' OR 'category' OR 'all'.
 *        'user' will only search for users.
 *        'product' will only search for products.
 *        'category' will only search for category.
 *        'all' will search all.
 *    text -> The text of the search button.
 *
 * This component will always check if the previous search string is
 * equal to current, if it is, then it will not conduct a new search.
 * This is checked with the prevStrings in the component state.
 *
 * To extend this component, GlobalDataController need to be updated with a global
 * searchString and a variable for storing the result of the search.
 *
 * This component only executes the search by the searchString given by
 * GlobalDataController. This means that somewhere else, the given searchString
 * needs to be set. For user, product and category search, this task is done by the
 * components called SearchInputField and CategorySelector.
 */
export class SearchButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prevUserString: "",
      prevProductString: "",
      prevCategoryString: ""
    };
  }

  theCallback = async () => {
    if (this.props.callback) {
      await this.props.callback();
    }
  };

  searchClick = async () => {
    await this.theCallback();
    if (this.props.searchType === 'user') {
      await this.findUser();
    }
    else if (this.props.searchType === 'product') {
      await this.filterProducts();
    }
    else if (this.props.searchType == 'category') {
      await this.filterProducts();
    }
    else {
      await this.findUser();
      await this.filterProducts();
    }
    await this.theCallback();
  };

  filterProducts = async () => {
      await this.findProduct();
      await this.findProductByCategory();
      let productsByString = GlobalDataController.productsBySearch;
      let productsByCategory = GlobalDataController.productsByCategorySearch;
      let productSearchString = GlobalDataController.productSearchString
      let categorySearchString = GlobalDataController.categorySearchString
      if(productSearchString !== "" && categorySearchString !== "") {
        let merge = productsByString.filter(product => {
            return productsByCategory.some(category => {
                return product._id === category._id;
            })
        })
        GlobalDataController.productsBySearch = merge;
      } else {
          if(productSearchString !== "") {
              GlobalDataController.productsBySearch = productsByString;
          }
          
          if(categorySearchString !== "") {
              GlobalDataController.productsBySearch = productsByCategory
          }
      }
  }

  findProductByCategory = async () => {
    if (GlobalDataController.categorySearchString) {
      var result = await searchProductByCategory(GlobalDataController.categorySearchString);
      GlobalDataController.productsByCategorySearch = await result;
    }
  };

  findProduct = async () => {
    if (GlobalDataController.productSearchString && GlobalDataController.productSearchString !== this.state.prevProductString) {
      var result = await searchProduct(GlobalDataController.productSearchString);
      GlobalDataController.productsBySearch = await result;
    }
    this.setState({
      prevProductString: GlobalDataController.productsBySearch
    });
  };

  findUser = async () => {
    if (GlobalDataController.userSearchString && GlobalDataController.userSearchString !== this.state.prevUserString) {
      var result = await searchUsername(GlobalDataController.userSearchString);
      GlobalDataController.usersBySeach = await result;
    }
    this.setState({
      prevUserString: GlobalDataController.userSearchString
    });
  };

  render() {
    return (<button style={{width: this.props.stretch ? "100%" : this.props.width ? this.props.width : ""}} onClick={this.searchClick}>{this.props.text}</button>);
  }
}
