import React, { Component } from "react";
import "./css/key-value-field-editor.css";
import Axios from "axios";
import { ItemLabel } from "./ItemLabel";
import { InputField } from "./InputField";
import RowLayout from "./RowLayout";
import ColumnLayout from "./ColumnLayout";
import { EditButton } from "./EditButton";
import { CheckButton } from "./CheckButton";

/**
 * A key value component model that represents the data of a user.
 * Each field is editable.
 *
 * Props: canEdit, keyValue, value, booleanValue.
 * canEdit is boolean and tells if the component should render the Edit button.
 * keyValue is the key name of the field.
 * value is the value of keyValue.
 * boooleanValue tells the component if this value should be handleed by true or false
 * values instead of String values.
 *
 * Author: Øyvind Johannessen
 * Version: 0.1
 */
class KeyValueFieldEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: " ",
      originalValue: " ",
      isEditable: false,
      keyValueObject: {}
    };

    this._handleOnChange = this._handleOnChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
      originalValue: this.props.value,
      keyValueObject: this.props.keyValueObject
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isHandle) {
      return {
        value: props.value
      };
    } else {
      return null;
    }
  }

  /**
   * Makes this field components key editable
   */
  _setEditable = () => {
    this.setState({ isEditable: true });
  };

  /**
   * Changes this components value in the state.
   * @param {event} e
   */
  _handleOnChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  _handleSelectBox(e) {
    if (e.target.value === " ") {
      this.setState({ value: this.state.originalValue });
    } else {
      this.setState({
        value: e.target.value === "yes" ? true : false
      });
    }
  }

  _handleCategoryBox(e) {
    if (e.target.value === " ") {
      this.setState({ value: this.state.originalValue });
    } else {
      this.setState(
        {
          value: e.target.value
        },
        () => this.props.setTemplateNameInParent(this.state.value)
      );
    }
  }

  /**
   * Persists new value to database
   */
  _commitToDatabase = () => {
    var { keyValueObject, value } = this.state;
    var key = Object.keys(keyValueObject)[0];
    keyValueObject[key] = value;

    switch (this.props.parentEditorName) {
      case "productEditor":
        this.setState({ keyValueObject: keyValueObject }, () => {
          const fd = new FormData();
          fd.append("productImage", value);
          Axios.post(
            "api/products/updateProduct/" + this.props.theId.toString(),
            this.props.imageSelector ? fd : keyValueObject
          )
            .then(res => {
              if (res.status === 200) {
                this.setState(
                  {
                    isEditable: false,
                    value: this.props.imageSelector
                      ? res.data.img
                      : this.state.value
                  },
                  () => {
                    if (this.props.imageSelector) {
                      this.props.setImage(res.data.img);
                    }
                  }
                );
                this.props.setHandle(res.data.handle);
              }
            })
            .catch(err => {
              console.log(err);
            });
        });
        break;
      case "userEditor":
        this.setState(
          {
            keyValueObject: keyValueObject
          },
          () => {
            Axios.post(
              "/api/users/updateUser/" + this.props.theId.toString(),
              keyValueObject
            )
              .then(res => {
                if (res.status === 200) {
                  this.setState({ isEditable: false });
                }
              })
              .catch(err => {
                console.log(err);
              });
          }
        );
        break;
      case "templateEditor":
        this.setState(
          {
            keyValueObject: keyValueObject
          },
          () => {
            Axios.post(
              "/api/product-category-template/update-template/" +
                this.props.theId.toString(),
              keyValueObject
            ).then(res => {
              if (res.status === 202) {
                this.setState({ isEditable: false });
              }
            });
          }
        );
    }
  };

  /**
   * Aborts the changes and resets to original value
   */
  _abort = () => {
    this.setState(
      { isEditable: false, value: this.state.originalValue },
      () => {
        if (this.props.setTemplateNameInParent) {
          this.props.setTemplateNameInParent(this.state.value);
        }
      }
    );
  };

  render() {
    const { isEditable, value } = this.state;
    const {
      canEdit,
      booleanValue,
      categorySelector,
      templateNames,
      imageSelector,
      numberField
    } = this.props;
    return !isEditable ? (
      <RowLayout
        borderBottom={true}
        stretchWidth={true}
        spaceTop={true}
        spaceBottom={true}
      >
        <RowLayout width="50%">
          <ItemLabel
            text={this.props.keyName + ": "}
            italic={true}
            color="#111"
            bold={true}
          />
        </RowLayout>
        <RowLayout width="100%">
          {booleanValue === false ? (
            <ItemLabel text={value} />
          ) : categorySelector === true ? (
            <ItemLabel text={value} />
          ) : value === true ? (
            <ItemLabel text="Ja" />
          ) : (
            <ItemLabel text="Nei" />
          )}

          <div style={{ marginLeft: "auto" }}>
            {canEdit ? (
              <EditButton
                onClick={this._setEditable}
                pushRight={true}
                width="20px"
                height="20px"
                margin="0 0 0 20px"
              />
            ) : (
              " "
            )}
          </div>
        </RowLayout>
      </RowLayout>
    ) : (
      <RowLayout
        borderBottom={true}
        stretchWidth={true}
        spaceTop={true}
        spaceBottom={true}
      >
        <RowLayout width="50%">
          <ItemLabel
            text={this.props.keyName + ": "}
            italic={true}
            color="#111"
            bold={true}
          />
        </RowLayout>
        <RowLayout width="100%">
          {booleanValue === false ? (
            imageSelector === true ? (
              <InputField
                type="file"
                name="img"
                placeholder="Bilde"
                onChange={e => this.setState({ value: e.target.files[0] })}
                marginLeft="0px"
                marginRight="0px"
                marginTop="0px"
                marginBottom="0px"
                padding="0 0 12px 0"
                fontSize="0.9em"
                width="200px"
              />
            ) : (
              <InputField
                type={numberField === true ? "number" : "text"}
                name="value"
                placeholder="Skriv inn passende verdi"
                value={value}
                onChange={this._handleOnChange}
                marginLeft="0px"
                marginRight="0px"
                marginTop="0px"
                marginBottom="0px"
                padding="0 0 12px 0"
                fontSize="0.9em"
              />
            )
          ) : categorySelector === true ? (
            <select
              className="categorySelectorOld"
              onChange={e => this._handleCategoryBox(e)}
            >
              <option value={value}>{value}</option>
              {templateNames.map(name => {
                return <option value={name}>{name}</option>;
              })}
            </select>
          ) : (
            <select
              className="categorySelectorOld"
              onChange={e => this._handleSelectBox(e)}
            >
              <option value={value === true ? "yes" : "no"}>
                {value === true ? "Ja" : "Nei"}
              </option>
              <option value="no">Nei</option>
              <option value="yes">Ja</option>
            </select>
          )}

          <div style={{ marginLeft: "auto" }}>
            <RowLayout>
              {this.state.value !== this.state.originalValue ? (
                <CheckButton
                  _commitToDatabase={this._commitToDatabase}
                  width="20px"
                  height="20px"
                  margin="0 5px 0 10px"
                />
              ) : (
                ""
              )}

              <EditButton
                onClick={this._abort}
                width="20px"
                height="20px"
                margin="0 0 0 5px"
              />
            </RowLayout>
          </div>
        </RowLayout>
      </RowLayout>
    );
  }
}

export default KeyValueFieldEditor;
