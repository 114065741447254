import React, { Component } from "react";
import {
  saveNewTemplateToDatabase,
  getTotalProductInventoryFromDatabase,
} from "../../utils/apiFunctions";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { loadTemplates } from "../../actions/templateActions";
import "./css/system-style.css";
import Changelog from "./Changelog";
import TemplateEditor from "./TemplateEditor";
import { getActiveBorrowers } from "../../utils/apiFunctions";
import ModalWindow from "../common/ModalWindow";
import UserItem from "../common/UserItem";
import ContentContainer from "../common/ContentContainer";
import ColumnLayout from "../common/ColumnLayout";
import RowLayout from "../common/RowLayout";

class System extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTemplateEditor: false,
      showActiveBorrowers: false,
      activeBorrowers: [],
    };
  }

  async componentDidMount() {
    this.props.loadTemplates();
    await this.setPastBorrowers();
  }

  setPastBorrowers = async () => {
    const activeBorrowers = await getActiveBorrowers();
    this.setState({
      activeBorrowers: activeBorrowers,
    });
  };

  /*
   * Sends a request to server for aggregated data of product inventory count and sets the state
   * This is used in the componentDidMount lifecycle method and the data is passed as props to relevant sub-components
   */
  _loadTotalInventory() {
    getTotalProductInventoryFromDatabase().then((res) => {
      this.setState({ inventory: res.data });
    });
  }

  _createTemplate() {
    Swal.fire({
      title: "Opprett Mal",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      html:
        '<div style="display: flex; flex-direction: column;">' +
        '<input class="swal2-input" style="text-transform:capitalize;" type="text" id="category" placeholder="Skriv inn kategori"/>' +
        '<span>Tittel: <input class="swal2-checkbox" type="checkbox" id="title" /></span>' +
        '<span>Produkt Nummer: <input class="swal2-checkbox" type="checkbox" id="productNum" /></span>' +
        '<span>Beskrivelse: <input class="swal2-checkbox" type="checkbox" id="description" /></span>' +
        '<span>Jegerprøven: <input class="swal2-checkbox" type="checkbox" id="huntingLicense" /></span>' +
        '<span>Båtførerprøven: <input class="swal2-checkbox" type="checkbox" id="boat" /></span>' +
        '<span>Våt sertifikat: <input class="swal2-checkbox" type="checkbox" id="wetCard" /></span>' +
        '<span>Brattkort: <input class="swal2-checkbox" type="checkbox" id="climbingLicense" /></span>' +
        '<span>Klasse BE: <input class="swal2-checkbox" type="checkbox" id="classBE" /></span>' +
        '<span>Produkt bilde: <input class="swal2-checkbox" type="checkbox" id="img" /></span>' +
        '<span>Verdi: <input class="swal2-checkbox" type="checkbox" id="value" /></span>' +
        '<span>Antall: <input class="swal2-checkbox" type="checkbox" id="count" /></span>' + // Denne blir aldri med i fremvisingen (rart)
        "</div>",
      preConfirm: () => {
        const template = {
          category: document.getElementById("category").value,
          title: document.getElementById("title").checked,
          value: document.getElementById("value").checked,
          productNum: document.getElementById("productNum").checked,
          description: document.getElementById("description").checked,
          huntingLicense: document.getElementById("huntingLicense").checked,
          boat: document.getElementById("boat").checked,
          wetCard: document.getElementById("wetCard").checked,
          climbingLicense: document.getElementById("climbingLicense").checked,
          classBE: document.getElementById("classBE").checked,
          img: document.getElementById("img").checked,
          count: document.getElementById("count").checked,
        };
        return saveNewTemplateToDatabase(template)
          .then((res) => {
            if (
              res.data === "Kategori må fylles ut" ||
              res.data === "Mal for denne kategorien finnes allerede"
            ) {
              throw new Error(res.data);
            }
            return res;
          })
          .catch((err) => {
            console.log(err);
            Swal.showValidationMessage(err);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((res) => {
      console.log(res);
      if (res.dismiss !== "cancel" && res.dismiss !== "overlay") {
        Swal.fire({
          title: "Mal opprettet",
          type: "success",
        }).then(() => {
          this.componentDidMount();
        });
      }
    });
  }
  _sendMail() {
    window.location.href = "mailto:fjellfjordit@gmail.com";
  }

  render() {
    const {
      showTemplateEditor,
      activeBorrowers,
      showActiveBorrowers,
    } = this.state;
    return (
      <ContentContainer>
        <ColumnLayout className="align-center">
          <RowLayout className="button-row">
            <button
              className="system-btn-template"
              onClick={() => {
                this._sendMail();
              }}
            >
              Kontakt oss
            </button>
            <button
              className="system-btn-template"
              onClick={() => {
                this._createTemplate();
              }}
            >
              Opprett Kategori Mal
            </button>
            <button
              className="system-btn-template"
              onClick={() => this.setState({ showTemplateEditor: true })}
            >
              Rediger Kategori Mal
            </button>
            <button
              className="system-btn-template"
              onClick={() => this.setState({ showActiveBorrowers: true })}
            >
              Vis Aktive Låntakere
            </button>
          </RowLayout>
          <h2>Changelog</h2>
          <Changelog />
          {!showTemplateEditor ? (
            ""
          ) : (
            <TemplateEditor
              parentVisibility={() =>
                this.setState({ showTemplateEditor: false })
              }
            />
          )}
          {!showActiveBorrowers ? (
            ""
          ) : (
            <ModalWindow
              header="Aktive Låntakere"
              desc="Klikk på bruker for å se låneskjema. Rød skrift på navn er låntakere som ikke har levert tilbake produkter innen avtalt dato."
              children={activeBorrowers.map((user) => {
                let missedDueDates = false;
                user.borrows.forEach((product) => {
                  if (
                    new Date(product.loanEnd) <
                    new Date(new Date().toDateString())
                  ) {
                    missedDueDates = true;
                  }
                });
                if (missedDueDates) {
                  return (
                    <UserItem
                      className="pointer"
                      color="red"
                      name={
                        user.name +
                        " Låner " +
                        user.borrows.length +
                        " produkter."
                      }
                      id={user.user_id}
                      schemaTitle={user.name}
                      borrowModalSchemaOnClick={true}
                      setPastBorrowers={this.setPastBorrowers}
                    />
                  );
                }
                return (
                  <UserItem
                    className="pointer"
                    name={
                      user.name +
                      " Låner " +
                      user.borrows.length +
                      " produkter."
                    }
                    id={user.user_id}
                    borrowModalSchemaOnClick={true}
                    setPastBorrowers={this.setPastBorrowers}
                  />
                );
              })}
              parentVisibility={() =>
                this.setState({ showActiveBorrowers: false })
              }
            />
          )}
        </ColumnLayout>
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  templateNames: state.template.map((tempName) => tempName.category),
});

export default connect(mapStateToProps, { loadTemplates })(System);
