import React, { Component } from "react";
import ReactDOM from "react-dom";
import { logoutUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Profile from "./Profile";
import { isSysDevSysAdmOrLender } from "../../utils/conditionalRenderFunctions";
// CSS
import "./css/sidemenu-style.css";
import { FixedPageHeader } from "./FixedPageHeader";
import GlobalDataController from "../../utils/GlobalDataController";
function makeActive(element) {
  var node = ReactDOM.findDOMNode(element);
  if (node) {
    node.classList.toggle("active");
  }
}
class Sidemenu extends Component {
  logoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }

  setPageTitleHeader = (pageName) => {
    GlobalDataController.pageTitleHeader = pageName;
  };

  render() {
    return (
      <div className="sidebar-content">
        <nav className="sidebar" id="sidebar">
          <FixedPageHeader title={GlobalDataController.pageTitleHeader} />
          <div className="sidebar-header">
            <Profile />
          </div>
          <ul className="list-unstyled components">
            {isSysDevSysAdmOrLender() ? (
              <div>
                <li onClick={() => this.setPageTitleHeader("Dashbord")}>
                  <Link to="/dashbord" className="menuButton">
                    <span className="fa fa-chart-bar" />
                    Dashbord
                  </Link>
                </li>
                <li onClick={() => this.setPageTitleHeader("Utlån")}>
                  <Link to="/utlan" className="menuButton">
                    <span className="fa fa-arrow-left" />
                    Utlån
                  </Link>
                </li>
                <li onClick={() => this.setPageTitleHeader("Låntaker")}>
                  <Link to="/lantaker" className="menuButton">
                    <span className="fa fa-address-card" />
                    Låntaker
                  </Link>
                </li>
              </div>
            ) : (
              ""
            )}
            <li onClick={() => this.setPageTitleHeader("Produkt")}>
              <Link to="/produkt" className="menuButton">
                <span className="fa fa-boxes" />
                Produkt
              </Link>
            </li>

            {isSysDevSysAdmOrLender() ? (
              <li onClick={() => this.setPageTitleHeader("System")}>
                <Link to="/system" className="menuButton">
                  <span className="fa fa-chart-bar" />
                  System
                </Link>
              </li>
            ) : (
              ""
            )}

            <li>
              <a
                href="/"
                onClick={this.logoutClick.bind(this)}
                className="menuButton"
              >
                <span className="fa fa-sign-out-alt" />
                Logg ut
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

Sidemenu.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Sidemenu);
