import React, { Component } from 'react';
import ColumnLayout from './ColumnLayout';
import RowLayout from './RowLayout';
import { ItemLabel } from './ItemLabel';
import { getAllUsersBorrowingAProduct } from "../../utils/apiFunctions";
import UserItem from './UserItem';
import Tooltip from './Tooltip';

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      header: undefined,
      borrowers: undefined,
      index: undefined
    }
  }

  setHeader = (header) => {
    this.setState({
      header: header
    })
  }

  showProductBorrower = async (productId, index) => {
    if (index === this.state.index) {
      this.setState({
        borrowers: undefined,
        index: undefined
      })
    } else {
      const borrower = await getAllUsersBorrowingAProduct(productId);

      let borrowers = [];

      borrower.data.forEach(borrower => {
        borrowers.push({ userId: borrower.user_id, name: borrower.name })
      })

      this.setState({
        borrowers: borrowers,
        index: index
      })
    }
  }

  render() {
    const { header, borrowers } = this.state;
    return (
      <div className="swipeInLeftAnimation" style={{ height: this.state.header ? "50%" : "", width: "calc(100% - 225px)", backgroundColor: "#A6A6A6" }}>
        {!header ? <RowLayout stretchWidth={true}>
          {this.props.headers.map((aHeader, index) => (
            <Tooltip text={`Trykk for å vise ${aHeader} som er utlånt.`}>
              <button onClick={() => { this.setHeader(aHeader) }} key={index}>{aHeader}</button>
            </Tooltip>
          ))}
        </RowLayout> :

          <ColumnLayout stretchWidth={true} stretchHeight={true} scrollable={true} spaceLeft={true} spaceRight={true} className="swipeInLeftAnimation">

            {this.props.data[header].map((product, index) => (
              <div>
                <RowLayout key={index} stretchWidth={true} className="elementHover">

                  <ColumnLayout>
                    {index === 0 ? <ItemLabel className="indexCell" text="Index" bold={true} /> : ""}
                    <ItemLabel className="indexCell" text={index + 1} />
                  </ColumnLayout>

                  <ColumnLayout>
                    {index === 0 ? <ItemLabel className="tableCell" text="Tittel" bold={true} /> : ""}
                    <ItemLabel className="tableCell" text={product.title} />
                  </ColumnLayout>

                  <ColumnLayout>
                    {index === 0 ? <ItemLabel className="tableCell" text="Antall" bold={true} /> : ""}
                    <ItemLabel className="tableCell" text={product.count} />
                  </ColumnLayout>

                  <ColumnLayout>
                    {index === 0 ? <ItemLabel className="tableCell" text="Produkt Nummer" bold={true} /> : ""}
                    <ItemLabel className="tableCell" text={product.productNum} />
                  </ColumnLayout>

                  <ColumnLayout stretchWidth={true}>
                    {index === 0 ? <ItemLabel className="tableCell" text="Låntakere" bold={true} /> : ""}
                    <RowLayout stretchWidth={true}>
                      <button className="tableCell tableCell-button" style={{
                        borderRadius: borrowers && this.state.index === index ? "0" : "4px",
                        borderBottomRightRadius: borrowers && this.state.index === index ? "0" : "4px",
                        backgroundColor: borrowers && this.state.index === index ? "#36383C" : "#1A1A1A"
                      }} onClick={() => { this.showProductBorrower(product._id, index) }}>Vis låntaker</button>

                      {borrowers && this.state.index === index ? <div className="extensionContainer extendAnimation">
                        {borrowers.map((borrower) => (
                          <Tooltip text={`${borrower.name}: Trykk for å vise låneskjema`}>
                            <UserItem
                              key={borrower.userId}
                              className="pointer"
                              name={borrower.name}
                              schemaTitle={borrower.name}
                              id={borrower.userId}
                              borrowModalSchemaOnClick={true}
                            />
                          </Tooltip>
                        ))}
                      </div> : ""}
                    </RowLayout>
                  </ColumnLayout>

                </RowLayout>
              </div>
            ))}
          </ColumnLayout>

        }
      </div>
    );
  }
}

export default DataTable;