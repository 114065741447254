import React from "react";
import GlobalDataController from "../../utils/GlobalDataController";
import { getAllCategories } from "../../utils/apiFunctions";

const style = {
  backgroundColor: "rgba(0,0,0,0.0)",
  padding: "12px 20px",
  margin: "8px 0",
  display: "inline-block",
  outline: "none"
}

export class CategorySelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: "",
      categories: []
    };
  }

  async componentDidMount() {
    let categories = await getAllCategories();
    this.setState({
      categories: categories
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      GlobalDataController.categorySearchString = this.state.category;
      this.shareCategory()
    });
  };

  shareCategory() {
    if(this.props.useCategory) {
      this.props.useCategory(this.state.category);
    }
  }

  render() {
    return (<select style={style} name="category" id="selectCategory" onChange={this.onChange}>
      <option value="" index={0}>Ingen Kategori</option>
      {this.state.categories.map((name, index) => {
        return <option value={name} index={index  + 1}>{name}</option>;
      })}
    </select>);
  }
}
