import React, { Component } from "react";
// DayPicker CSS
import "react-day-picker/lib/style.css";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  clearCart,
  removeCartItem,
  setCartItems
} from "../../actions/shoppingcartActions";
import { registerShoppingcartProductsToDatabase, searchProduct } from "../../utils/apiFunctions";
import GlobalDataController from "../../utils/GlobalDataController";
import CartItem from "../common/CartItem";
import PeriodPicker from "../common/PeriodPicker";
import { PopMsg } from "../common/PopupMessage";
import CartContainer from "../common/CartContainer";
import { CountStickerLabel } from "../common/CountStickerLabel";
import Tooltip from "../common/Tooltip";

import store from "../../store";

let cartSwal = withReactContent(Swal);

// Søk igjennom arrayet og dersom den unike IDen allerede eksisterer, øk count
class ShoppingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shoppingCart: [],
      loanStart: undefined,
      loanEnd: undefined,
    };

    this._setLoanPeriod = this._setLoanPeriod.bind(this);
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      shoppingCart: nextProps.shoppingCart
    };
  }

  /**
   * Function for setting the loan period.
   * This function is called by the PeriodPicker component
   * because this is the component that hold the 'from' and 'to' dates
   * selected by the user
   * Author: Øyvind Johannessen, Martin Svendsen
   * Version: 1.0
   */
  _setLoanPeriod(from, to) {
    if (!to) {
      this.setState({ loanStart: from, loanEnd: from })
    } else {
      this.setState({ loanStart: from, loanEnd: to })
    }
  }

  /**
   * Dete an item from the shoppingCart
   * @param {*} itemsList
   * @param {*} itemId
   */
  _delete = (itemsList, itemId) => {

    var filtered = itemsList.filter(item => item.id !== itemId);

    this.props.removeCartItem(filtered);

  }

  resetCart = () => {
    this.props.shoppingCart.items.forEach(item => {
      item.ref.setState({
        isInCart: false
      })
    })

    this.props.clearCart()
  }

  updateState = (items) => {
    this.props.setCartItems(items)
    console.log(this.props.shoppingCart)
  }

  openCart = () => {
    const shoppingCart = this.props.shoppingCart;
    const items = shoppingCart.items;
    const length = items.length;
    const user = shoppingCart.user;

    if (length > 0) {
      cartSwal
        .fire({
          title: "Handlekurv",
          html: (
            <CartContainer userName={user.name} cartItems={this.props.shoppingCart.items} deleteState={this._delete} updateState={this.updateState} />
          ),
          confirmButtonText: "Sjekk ut",
          showCancelButton: true,
          cancelButtonText: "Slett handlekurv"
        })
        .then(res => {
          if (res.dismiss === "cancel") {
            cartSwal
              .fire({
                title: "Er du sikker på at du vil slette handlekurven?",
                showCancelButton: true,
                cancelButtonText: "Avbryt"
              })
              .then(res => {
                if (res.value === true) {
                  this.resetCart();
                }
              });
          } else if (res.value === true) {
            // Her er "sjekk ut" knappen sin funksjon
            if (user.name !== " ") {
              // If user is selected, do this...
              cartSwal
                .fire({
                  title: "Velg periode for utlån",
                  html: (
                    <PeriodPicker
                      sendPeriodToShoppingCartState={this._setLoanPeriod}
                    />
                  ),
                  showCancelButton: true
                })
                .then(res => {
                  if (res.value === true) {
                    var newItems = []
                    items.forEach(item => {
                      newItems.push({
                        name: item.name,
                        id: item.id,
                        count: item.count,
                        img: item.img,
                        loanStart: this.state.loanStart,
                        loanEnd: this.state.loanEnd
                      })
                    });
                    registerShoppingcartProductsToDatabase(user, newItems)
                      .then(response => {
                        if (response.status === 202) {
                          Swal.fire({
                            title: "Utlån registrert",
                            text: `Låntaker ${user.name}`,
                            type: "success"
                          }).then(() => {
                            items.forEach(item => {
                              let originalCount = item.ref.state.count;
                              let decrementCount = item.count;
                              item.ref.setState({
                                isInCart: false,
                                count: originalCount - decrementCount
                              })
                              GlobalDataController.productsBySearch = []
                            })
                            this.props.clearCart();
                          });
                        }
                      })
                      .catch(err => {
                        console.log(err);
                      });
                  }
                });
            } else {
              PopMsg({
                message: "❌ Låntaker ikke valgt",
                right: "0",
                left: "None",
                color: "#FEEFB3"
              });
            }
          }
        });
    } else {
      PopMsg({
        message: "❌ Handlekurven er tom",
        left: "None",
        right: "0",
        color: "#FFD2D2"
      });
    }
  };

  render() {
    return (
      <div style={{ position: "relative", right: "0" }}>
        <a onClick={this._openCart}>
          <Tooltip text="Trykk for å åpne handlekurv.">
            <img
              src={require("../../img/ic_shopping_basket_48px.png")}
              alt="Handle kurv"
              className="iconButtonActive pointer"
              onClick={this.openCart}
            />
          </Tooltip>
        </a>
        {this.props.shoppingCart.items.length !== 0 ? <CountStickerLabel count={this.props.shoppingCart.items.length} top="10px" left="-5px" backgroundColor="green" width="6px" height="6px" countTop="-1px" /> : ""}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  shoppingCart: state.shoppingCart,
  countFromProps: state.countFromProps
});

export default connect(
  mapStateToProps,
  {
    clearCart,
    removeCartItem,
    setCartItems,
    store
  }
)(ShoppingCart);
