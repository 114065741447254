import React, { Component } from 'react';
import './css/modal-window.css';

class ModalWindow extends Component {
    constructor(props) {
        super(props);
        this.state = {
          isVisible: true
        };
      }

    _close() {
        this.setState(
          {
            isVisible: false
          },
          () => this.props.parentVisibility()
        );
      }

    render() {
        return (
            <div className="ModalFrame">
                <div className="TemplateEditorHeader">
                    <h3 style={{marginTop: "0px"}}>{this.props.header}</h3>
                    <i
                        className="fa fa-times"
                        style={{marginLeft: 'auto'}}
                        onClick={() => {this._close();}}
                    />
                </div>
                <span style={{color: "green"}}>{this.props.desc}</span>
                {this.props.children}
            </div>
        );
    }
}

export default ModalWindow;