import React, { Component } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { ItemLabel } from './ItemLabel';
import ColumnLayout from './ColumnLayout';

class BarChartComponent extends Component {
  render() {
    return (
      <div style={{height: "300px", width: "calc(100% - 225px)", marginTop: "auto", backgroundColor: "#A6A6A6"}}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={this.props.data}
            margin={{
              top: 20, bottom: 5, right: 50
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={this.props.xKey} />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey={this.props.yKey} fill="#877040" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default BarChartComponent;