import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import profileReducer from "./profileReducer";
import templateReducer from "./templateReducer";
import shoppingCartReducer from "./shoppingCartReducer";
import appendingUsersReducer from "./appendingUsersReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  template: templateReducer,
  shoppingCart: shoppingCartReducer,
  appendingUsers: appendingUsersReducer
});
