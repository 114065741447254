import React from "react";


const style = {
    maxHeight: "85px",
    maxWidth: "85px",
    minHeight: "85px",
    minWidth: "85px",
    objectFit: "cover",
    borderRadius: "8px"
}

export class ItemImage extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div onClick={this.props.onClick} className="swipeInRightAnimation" style={{
                borderRadius: "8px", maxHeight: "85px",
                maxWidth: "85px",
                minHeight: "85px",
                minWidth: "85px",
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            }}>
                <img style={style} alt="" src={this.props.image === "data:image/png;base64,undefined" ? "https://dummyimage.com/180x180/fff/000000&text=No+Image" : this.props.image} />
            </div>
        );
    }
}
