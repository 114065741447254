import React, { Component } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { withSwalInstance } from "sweetalert2-react";
import BorrowItem from "./BorrowItem";
import { connect } from "react-redux";
import { setCartUser } from "../../actions/shoppingcartActions";
import { PopMsg } from "../common/PopupMessage";
import {
  redeliverBorrowedProduct,
  getSingleUserBorrowSchema
} from "../../utils/apiFunctions";
import UserEditor from "../borrower/UserEditor";
import { AddUserButton } from "./AddUserButton";
import { ItemImage } from "./ItemImage";
import { ItemLabel } from "./ItemLabel";
import RowLayout from "./RowLayout";
import ColumnLayout from "./ColumnLayout";
import GlobalDataController from "../../utils/GlobalDataController";
import BorrowSchemaEditor from "../common/BorrowSchemaEditor";
import Tooltip from "../common/Tooltip";
import { EditButton } from "../common/EditButton";

const SweetAlert = withSwalInstance(withReactContent(Swal));


/**
 * This class presents a user in the database as a component
 * Author: Øyvind Johannessen
 * Extended by: Martin Svendsen
 * Version 0.2
 */
class UserItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schema: undefined,
      showSchema: false,
      editUser: false
    };
  }
  /**
   * Select a user that is going to borrow a product
   */
  _selectUser = () => {
    var user = { name: this.props.name, id: this.props.id };
    this.props.setCartUser(user);
    PopMsg({
      message: "Låntaker valgt",
      left: "None",
      right: "0",
      color: "green"
    });
  };

  componentDidMount() {
    this.getUserBorrowSchema();
  }

  toggleEditUser = () => {
    const { editUser } = this.state;
    this.setState({
      editUser: editUser ? false : true
    })
  }

  onClick = () => {
    // shareSchema function is only called if UserItem receives a parent component as props though the parent property.
    this.shareSchema();
    // showBorrowSchemaModal function is only called if UserItem receives "true" on the property borrowModalSchemaOnClick.
    this.showBorrowSchemaModal();
  };

  showBorrowSchemaModal = () => {
    if (this.props.borrowModalSchemaOnClick) {
      const { showSchema } = this.state;
      this.setState(
        {
          showSchema: showSchema ? false : true
        },
        () => {
          if (!this.state.showSchema) {
            // setPastBorrowersInParent will only run if setPastBorrowers prop is not undefined
            this.setPastBorrowersInParent();
          }
        }
      );
    }
  };

  shareSchema = () => {
    if (this.props.parent) {
      this.props.parent.setState({
        userSchema: this.state.schema,
        userName: this.props.name + "'s",
        userId: this.props.id,
        userItem: this
      });
    }
  };

  getUserBorrowSchema() {
    getSingleUserBorrowSchema(this.props.id).then(res => {
      const schema = res.data.borrows;
      this.setState({
        schema: schema
      });
    }).catch(console.log);
  }

  updateUserSchema = () => {
    this.getUserBorrowSchema();
  };

  setPastBorrowersInParent = () => {
    if (this.props.setPastBorrowers) {
      this.props.setPastBorrowers();
    }
  };

  render() {
    // Properties
    const id = this.props.id;
    const imgSrc = this.props.imgSrc;
    const name = this.props.name;
    const empNum = this.props.empNum;
    const color = this.props.color;

    const addUserButton = this.props.includeAddButton ? (
      <Tooltip text={`Legg til ${name} som låntaker i handlekurv.`}>
        <AddUserButton
          _selectUser={this._selectUser}
          pushRight={true}
          pushBottom={true}
          pushTop={true}
          padding="0px 10px 0px 10px"
        />
      </Tooltip>
    ) : (
        " "
      );

    const editUserButton = this.props.includeEditButton ? <EditButton width="25px" height="25px" margin="auto 10px auto 0" onClick={this.toggleEditUser} /> : "";

    return (
      <div
        className={
          this.props.className
            ? this.props.className + " bottomPadding"
            : "bottomPadding"
        }
        style={{
          maxWidth: "400px",
          minWidth: "400px",
          margin: "10px",
          animation: "fadein 0.5s"
        }}
        label={id}
      >
        <RowLayout stretchWidth={true}>
          <ItemImage
            onClick={this.onClick}
            image={
              imgSrc
                ? imgSrc
                : "https://dummyimage.com/180x180/ddd/000000&text=No+Image"
            }
          />
          <ColumnLayout
            stretchWidth={true}
            spaceLeft={true}
            pushTop={true}
            pushBottom={true}
          >
            <ItemLabel text={empNum} italic={true} color="#686868" />
            <ItemLabel text={name} color={color} />
          </ColumnLayout>
          {addUserButton}
          {editUserButton}
        </RowLayout>
        <SweetAlert
          show={this.state.showSchema}
          title={this.props.schemaTitle + "'s låneskjema"}
          onConfirm={this.showBorrowSchemaModal}
          html={
            <BorrowSchemaEditor
              items={this.state.schema}
              userId={id}
              parent={this}
            />
          }
        />
        {this.state.editUser ? <UserEditor id={id} /> : ""}
      </div>
    );
  }
}

export default connect(null, { setCartUser })(UserItem);
