import React, { Component } from 'react';
import ContentContainer from "./common/ContentContainer"
import RowLayout from "./common/RowLayout";
import TodoItem from "./dashboard/dashboard-components/TodoItem";
import BarChartComponent from "./common/BarChartComponent";
import ChartDataFunctions from "../utils/ChartDataFunctions";
import TrayContainer from './common/TrayContainer';
import ColumnLayout from './common/ColumnLayout';
import Tooltip from "./common/Tooltip";
import Loading from './common/Loading';
import UserItem from "./common/UserItem";
import UserEditor from './borrower/UserEditor';

/**
 * This container can be used to design components with having to do a search every time to see any changes to a component
 * inside the system. Just import the component in to this component and use the url: localhost:3000/design and start designing.
 */
class DesignContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    render() {
        const { data } = this.state;
        return (
            <ContentContainer>

                <ColumnLayout stretchHeight={true} stretchWidth={true}>
                    <Tooltip text={`Øyvind Johannessen: Trykk på bilde for å vise låneskjema.`}>
                        <UserItem
                            growValue="1"
                            key={"5d8675c3197dd2ba33a5085d"}
                            name={"Øyvind Johannessen"}
                            empNum={77777}
                            imgSrc={"//www.gravatar.com/avatar/80f3fb9f2de740b0e2e6e10a6f0a5531?s=200&r=pg&d=mm"}
                            id={"5d8675c3197dd2ba33a5085d"}
                            includeAddButton={false}
                            includeEditButton={true}
                            parent={this}
                            className="ItemHover pointer"
                        />
                    </Tooltip>
                    
                </ColumnLayout>

            </ContentContainer>
        );
    }
}

export default DesignContainer;