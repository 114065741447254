import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { loadTemplates } from "../../actions/templateActions";
import "./css/template-editor-style.css";
import KeyValueFieldEditor from "../common/KeyValueFieldEditor";
import swal from "sweetalert2";

class TemplateEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateNames: [],
      categoryValue: "",
      template: "",
      fields: [],
      isVisible: true,
      isLoading: true
    };

    this.onChange = this.onChange.bind(this);
    this._generateFields = this._generateFields.bind(this);
  }

  onChange(e) {
    const value = e.target.value;
    this.setState({ isLoading: true }, () => {
      this.setState({ categoryValue: value }, () => {
        axios
          .get(
            "/api/product-category-template/get-template/" +
              this.state.categoryValue
          )
          .then(res => {
            this.setState({ template: res.data }, () => {
              this._generateFields();
              this.setState({
                isLoading: false
              });
            });
          });
      });
    });
  }

  componentDidMount() {
    this.props.loadTemplates();
    this.setState({
      templateNames: this.props.templates.map(tempName => tempName.category)
    });
  }

  _close() {
    this.setState(
      {
        isVisible: false
      },
      () => this.props.parentVisibility()
    );
  }

  _capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  _generateFields() {
    const { template } = this.state;
    var keyValueFields = [];
    for (var key in template) {
      if (
        key.toString() !== "category" &&
        key.toString() !== "_id" &&
        key.toString() !== "__v" &&
        key.toString() !== "productNum" &&
        key.toString() !== "userDemands"
      ) {
        keyValueFields.push(
          <KeyValueFieldEditor
            key={key.toString()}
            keyName={swapNames(key.toString())}
            keyValueObject={{ [key]: template[key] }}
            value={template[key]}
            canEdit={true}
            booleanValue={true}
            theId={template._id}
            parentEditorName="templateEditor"
            isHandle={false}
          />
        );
      }
      if (key.toString() === "userDemands") {
        var userDemands = template[key];
        for (var key in userDemands) {
          keyValueFields.push(
            <KeyValueFieldEditor
              key={key.toString()}
              keyName={swapNames(key.toString())}
              keyValueObject={{ [key]: template[key] }}
              value={userDemands[key]}
              canEdit={true}
              booleanValue={true}
              theId={template._id}
              parentEditorName="templateEditor"
              isHandle={false}
            />
          );
        }
      }
    }

    function swapNames(name) {
      switch (name) {
        case "title":
          return "Tittel";
        case "description":
          return "Beskrivelse";
        case "huntingLicense":
          return "Jeger Sertifikat";
        case "boat":
          return "Båt Sertifikat";
        case "wetCard":
          return "Våt Sertifikat";
        case "climbingLicense":
          return "Brattkort";
        case "classBE":
          return "Klasse BE";
        case "img":
          return "Bilde";
        case "value":
          return "Verdi";
        case "count":
          return "Antall";
      }
    }

    this.setState({ fields: keyValueFields });
  }

  /**
   * Delete a template
   */
  _delete() {
    swal
      .fire({
        type: "warning",
        title: "Advarsel",
        text:
          "Hvis er produkt under denne kategorien er til utlån kan man ikke slette malen før produktene er tilbakelevert. Hvis alle produkter under denne kategorien  (" +
          this.state.template.category +
          ") er tilbakelevert og man velger å slette, vil alle de tilhørende produktene endre sin kategori til 'Ukategorisert'. Dette er en standard mal som kan brukes hvor alle krav for en mal er tillatt.",
        showCancelButton: true,
        cancelButtonText: "Avbryt"
      })
      .then(res => {
        if (res.value === true) {
          axios
            .post(
              "/api/product-category-template/delete/" +
                this.state.template.category,
              { id: this.state.template._id }
            )
            .then(res => {
              if (res.data.message === false) {
                swal.fire({
                  type: "error",
                  title: "Kan ikke slette " + this.state.template.category,
                  text:
                    "Denne malen kan ikke slettes fordi det er folk som låner produkter under denne kategorien. Når alle produkter er tilbakelevert kan malen slettes."
                });
              } else {
                this.props.loadTemplates();
                this._close();
                swal.fire({
                  type: "success",
                  title: this.state.template.category + " har blitt slettet",
                  text:
                    "De tilhørende produktene er blitt endret til kategorien 'Ukategorisert.'"
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      });
  }

  render() {
    const {
      templateNames,
      template,
      categoryValue,
      isLoading,
      fields
    } = this.state;
    return (
      <div>
        <div className="TemplateEditor">
          <div className="TemplateEditorHeader">
            <div className="ChooseCategoryContainer">
              <label>Velg Kategori Mal</label>
              {templateNames.length === 0 ? (
                ""
              ) : (
                <select
                  className="select-category-template"
                  name="input"
                  placeholder="Velg kategori"
                  value={this.state.categoryValue}
                  onChange={this.onChange.bind(this)}
                >
                  <option key="default" value="" selected="selected"></option>
                  {templateNames.map(name => {
                    if (name !== "Ukategorisert") {
                      return (
                        <option key={name} value={name}>
                          {name}
                        </option>
                      );
                    }
                  })}
                </select>
              )}
            </div>
            <i
              className="fa fa-times"
              onClick={() => {
                this._close();
              }}
            />
          </div>
          {template.title}
          {isLoading ? (
            ""
          ) : (
            <div>
              {fields}
              <button id="delete-button" onClick={() => this._delete()}>
                Slett {template.category}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  templates: state.template
});

export default connect(
  mapStateToProps,
  { loadTemplates }
)(TemplateEditor);
