import React, { Component } from 'react';
import ColumnLayout from "./ColumnLayout";
import TrayBar from './TrayBar';
import { ItemLabel } from './ItemLabel';

class TrayContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTray: undefined,
      trayHandle: undefined,
      index: undefined
    };
  }

  openTray = (index, trayHandle) => {
    if (index === this.state.index) {
      this.setState({
        selectedTray: undefined,
        trayHandle: undefined,
        index: undefined
      });
    }
    else {
      this.setState({
        selectedTray: this.props.trayList[index],
        trayHandle: trayHandle,
        index: index
      });
    }
  };

  render() {
    const { selectedTray, trayHandle } = this.state;
    return (

        <ColumnLayout
          borderRadius="8px"
          className="trayDisplay"
          stretchHeight={selectedTray ? true : false}
          
        >

          <div className="blur"></div>

          <ItemLabel text={trayHandle ? trayHandle : ""} bold={true} />
          {selectedTray ? selectedTray : ""}
          <TrayBar onClick={this.openTray} trayHandles={this.props.trayHandles} />
        </ColumnLayout>

    );
  }
}

export default TrayContainer;