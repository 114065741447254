import React, { Component } from "react";
import { getImageFromDatabase } from "../../utils/apiFunctions";
import { ItemImage } from "./ItemImage";
import RowLayout from "./RowLayout";
import ColumnLayout from "./ColumnLayout";
import { ItemLabel } from "./ItemLabel";
import { CountStickerLabel } from "./CountStickerLabel";

class CartItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countInCart: 1,
      image: "https://dummyimage.com/180x180/fff/000000&text=No+Image"
    };
  }

  componentDidMount() {
    if (this.props.imgSrc !== " ") {
      getImageFromDatabase(this.props.imgSrc).then(res => {
        this.setState({
          image: "data:image/png;base64," + res
        });
      })
    }
  }

  incrementCount = () => {
    if (this.state.countInCart < this.props.max) {
      var newCount = this.state.countInCart + 1
      this.setState({
        countInCart: newCount
      }, () => this.props.updateCart(this.props.id, this.state.countInCart))
    }
  }

  decrementCount = () => {
    if (this.state.countInCart > this.props.min) {
      var newCount = this.state.countInCart - 1
      this.setState({
        countInCart: newCount
      }, () => this.props.updateCart(this.props.id, this.state.countInCart))
    }
  }

  render() {
    return (
      <div
        className={this.props.className ? this.props.className + " bottomPadding" : "bottomPadding"}
        style={{ position: "relative", maxWidth: "400px", minWidth: "400px", margin: "10px", animation: "fadein 1s" }}
        label={this.props.id}
        onClick={this.shareProductIdWithParent}
      >
        <RowLayout stretchWidth={true}>
          <ItemImage image={this.state.image} />
          <ColumnLayout stretchWidth={true} spaceLeft={true} pushTop={true} pushBottom={true}>
            <ItemLabel
              text={this.props.category}
              italic={true}
              color="#686868"
            />
            <ItemLabel text={this.props.name} />
          </ColumnLayout>
          <ColumnLayout stretchWidth={true}>
            <RowLayout marginTop="auto" marginBottom="auto" marginRight="auto" marginLeft="auto">
              <i
                onClick={this.decrementCount}
                style={{ marginLeft: "auto" }}
                className="fa fa-minus pointer iconButtonActive"
                aria-hidden="true"
              ></i>
              <ItemLabel
                text={this.state.countInCart}
                paddingRight="13px"
              />
              <i
                onClick={this.incrementCount}
                style={{ paddingRight: "0" }}
                className="fa fa-plus pointer iconButtonActive"
                aria-hidden="true"
              ></i>

            </RowLayout>

          </ColumnLayout>
        </RowLayout>

        <CountStickerLabel count={this.state.countInCart}></CountStickerLabel>
      </div>
    );
  }
}

export default CartItem;
