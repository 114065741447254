import React, { Component } from "react";

class ColumnLayout extends Component {
  render() {
    return (
      <div
        className={this.props.className ? this.props.className : ""}
        style={{
          display: "flex",
          backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : "transparant",
          flexDirection: "column",
          width: this.props.stretchWidth
            ? "100%"
            : this.props.width
              ? this.props.width
              : "",
          height: this.props.stretchHeight
            ? "100%"
            : this.props.height
              ? this.props.height
              : "",
          paddingLeft: this.props.spaceLeft
            ? "10px"
            : this.props.paddingLeft
              ? this.props.paddingLeft
              : "",
          paddingRight: this.props.spaceRight
            ? "10px"
            : this.props.paddingRight
              ? this.props.paddingRight
              : "",
          paddingTop: this.props.spaceTop
            ? "10px"
            : this.props.paddingTop
              ? this.props.paddingTop
              : "",
          paddingBottom: this.props.spaceBottom
            ? "10px"
            : this.props.paddingBottom
              ? this.props.paddingBottom
              : "",
          marginLeft: this.props.pushRight ? "auto" : this.props.marginLeft ? this.props.marginLeft : "",
          marginRight: this.props.pushLeft ? "auto" : this.props.marginRight ? this.props.marginRight : "",
          marginTop: this.props.pushBottom ? "auto" : this.props.marginTop ? this.props.marginTop : "",
          marginBottom: this.props.pushTop ? "auto" : this.props.marginBottom ? this.props.marginBottom : "",
          border: this.props.borders ? "1px solid black" : "",
          borderLeft: this.props.borderLeft ? "1px solid black" : "",
          borderRight: this.props.borderRight ? "1px solid black" : "",
          borderBottom: this.props.borderBottom ? "1px solid black" : "",
          borderTop: this.props.borderTop ? "1px solid black" : "",
          overflowY: this.props.scrollable ? "scroll" : "",
          borderRadius: this.props.borderRadius ? this.props.borderRadius : "",
          minHeight: this.props.minHeight ? this.props.minHeight : ""
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default ColumnLayout;
