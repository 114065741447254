import React from "react";
export const EditButton = (props) => (
  <img
    src={require("../../img/ic_edit_48px.png")}
    style={{
      display: "block",
      height: props.height ? props.height : "40px",
      width: props.width ? props.width : "40px",
      margin: props.margin,
      padding: props.padding
    }}
    onClick={props.onClick} className="pointer iconButtonActive" />
);
