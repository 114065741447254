import React, { Component } from 'react';

class Loading extends Component {
  render() {
    return (
      <div style={{display: "flex", fontSize:"16px", color: this.props.textColor, margin: this.props.margin}}>
        <div className="loading">
        <div id="rotated-loading">
          
        </div>
      </div>
      Loading..
      </div>
    );
  }
}

export default Loading;