import {
  SET_CART_USER,
  SET_CART_ITEMS,
  REMOVE_CART_USER,
  CLEAR_CART,
  REMOVE_CART_ITEM
} from "./types";

export const removeCartItem = filteredItems => dispatch => {
  dispatch({
    type: REMOVE_CART_ITEM,
    payload: filteredItems
  });
};

export const clearCart = () => dispatch => {
  dispatch({
    type: CLEAR_CART,
    payload: [] // Må kanskje ha et lignende objekt som initialState her
  });
};

export const removeCartUser = () => dispatch => {
  dispatch({
    type: REMOVE_CART_USER,
    payload: { name: " ", id: " " } // Må kanskje ha et lignende objekt som initialState her
  });
};

export const setCartItems = items => dispatch => {
  dispatch({
    type: SET_CART_ITEMS,
    payload: items // [ {id: ' ', count: 2} ]
  });
};

export const setCartUser = user => dispatch => {
  dispatch({
    type: SET_CART_USER,
    payload: user // {name: '' id: ''}
  });
};
