export default {
    // Global Variables
    pageTitleHeader: "Dashbord", 
    userSearchString: "",
    productSearchString: "",
    categorySearchString: "",
    usersBySeach: [],
    productsBySearch: [],
    productsByCategorySearch: [],
    categories: [],
    selectedUser: ""
}